import {deleteCustomer} from '../utils/CustomerUtls'
const initilastate ={
    Customers_List:[],
    totalPages:"",
    currentPage:"", 
    totalCustomers:"", 
    }

    const CustomerList =(state = initilastate,Action)=>{
        switch (Action.type) {
            case "GETCUSTOMERS":
                console.log(Action.payload)
                

                return{
                    ...state,
                    Customers_List:[...state.Customers_List,...Action.payload.Customers],
                    totalPages:Action.payload.totalPages,
                    currentPage:Action.payload.currentPage,
                    totalCustomers:Action.payload.totalCustomers,
                    }
            break;
            case "DELETECUSTOMER":
                return{
                    ...state,
                    Customers_List:deleteCustomer(state.Customers_List,Action.payload.customerID),
                    totalCustomers:Action.payload.totalCustomers,
                    totalPages:Action.payload.totalPages,
                    }
            break;
            case "EMPTYCUSTOMERS":
                return{
                    ...state,
                    Customers_List: [],
                    totalPages:"",
                    currentPage:"",
                    totalCustomers:"",
                    }
            break;
            default:
                return state
                break;
        }
    
    }
    
    export default CustomerList
    
    

