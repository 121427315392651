import {deleteUnit} from '../utils/UnitUtls'
const initilastate ={
    Units_List:[],
    totalPages:"",
    currentPage:"",
    totalUnits:"",
    }

    const UnitsList =(state = initilastate,Action)=>{
        switch (Action.type) {
            case "GETUNITS":
                console.log(Action.payload)
                

                return{
                    ...state,
                    Units_List:[...state.Units_List,...Action.payload.Units],
                    totalPages:Action.payload.totalPages,
                    currentPage:Action.payload.currentPage,
                    totalUnits:Action.payload.totalUnits,
                    }
            break;
            case "DELETEUNIT":
                return{
                    ...state,
                    Units_List:deleteUnit(state.Units_List,Action.payload.unitID),
                    totalUnits:Action.payload.totalUnits,
                    totalPages:Action.payload.totalPages,
                    }
            break;
            case "EMPTYUNITS":
                return{
                    ...state,
                    Units_List: [],
                    totalPages:"",
                    currentPage:"",
                    totalUnits:"",
                    }
            break;
            default:
                return state
                break;
        }
    
    }
    
    export default UnitsList
    
    

