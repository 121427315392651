import {GETUSERS,UPDATEUSER,DELETEUSER,EMPTYUSERS} from './types'
import axios from 'axios'

export const GetUsers =(data,setLoading)=>dispatch=>{
    const headers={
        "x-access-token": sessionStorage.getItem("accountingauthkey")
    }
    axios.post('/api/getusers',data,{headers:headers}).then(res=>{console.log(res) 
      setLoading(false)
    dispatch({type:GETUSERS,payload:res.data})})
}

export const EmptyUsers =()=>dispatch=>{
  dispatch({type:EMPTYUSERS})
}
export const UpdateUser =(user)=>dispatch=>{
  const headers={
      "x-access-token": sessionStorage.getItem("accountingauthkey")
  }
  axios('/api/updateuser',user,headers).then(res=>dispatch({type:UPDATEUSER,payload:res.message}))
}

export const DeleteUser =(userID,totalPages,totalUsers)=>(
  {type:DELETEUSER,
  payload:{userID,totalPages,totalUsers}}
)