import React, { useEffect,useState } from 'react'
import { Grid, } from '@material-ui/core';
import Controls from "../../components/controls/Controls";
import { useForm, Form } from '../../components/useForm';
import ConfirmDialog from "../../components/ConfirmDialog";



const initialFValues = {
    Unit_Name: '',
    System_Unit_ID:"",
    Pcs_In_Unit:''
}

export default function UnitForm(props) {
    const { addOrEdit, recordForEdit } = props
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('Unit_Name' in fieldValues)
            temp.Unit_Name = fieldValues.Unit_Name ? "" : "Name is not valid"
        if ('System_Unit_ID' in fieldValues)
            temp.System_Unit_ID = fieldValues.System_Unit_ID ? "" : "System Unit ID is not valid"
        if ('Pcs_In_Unit' in fieldValues)
            temp.Pcs_In_Unit = fieldValues.Pcs_In_Unit ? "" : "Pcs In Unit is not valid"
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {

        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }
    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            },console.log(initialFValues))
            
            console.log(recordForEdit)
    }, [recordForEdit])

    const onAddOrUdpateUnit = () => {
       
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        handleSubmit()
    }
    
    const onResetForm = () => {
       
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
       resetForm()
    }
    return (
        <>
        <Form onSubmit={(e) => {
            e.preventDefault()
            setConfirmDialog({
                isOpen: true,
                title: 'Are you sure to Submit',
                subTitle: "You can't undo this operation",
                onConfirm: () => { onAddOrUdpateUnit() }
                            })
                }}>
            <Grid container>
                <Grid xs={12}>
                    <Controls.Input
                        name="Unit_Name"
                        label="Unit Name"
                        value={values.Unit_Name}
                        onChange={handleInputChange}
                        error={errors.Unit_Name}
                    />

                    <Controls.Input
                        name="System_Unit_ID"
                        label="System Unit ID"
                        value={values.System_Unit_ID}
                        onChange={handleInputChange}
                        error={errors.System_Unit_ID}
                    />
                     <Controls.Input
                        name="Pcs_In_Unit"
                        label="Pcs In Unit"
                        value={values.Pcs_In_Unit}
                        onChange={handleInputChange}
                        error={errors.Pcs_In_Unit}
                    />
                    

                    <div style={{margin:".8rem"}}>
                        <Controls.Button
                        keyupfire={false}
                            type="submit"
                            text="Submit" />
                        <Controls.Button
                        keyupfire={false}
                            text="Reset"
                            color="default"
                            onClick={() => {
                                setConfirmDialog({
                                isOpen: true,
                                title: 'Are you sure to reset',
                                subTitle: "You can't undo this operation",
                                onConfirm: () => { onResetForm() }
                                })
                                }} />
                    </div>
                </Grid>
            </Grid>
        </Form>
          <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
      />
      </>
    )
}
