import React, { useEffect,useState } from 'react'
import { Grid, } from '@material-ui/core';
import Controls from "../../components/controls/Controls";
import { useForm, Form } from '../../components/useForm';
import ConfirmDialog from "../../components/ConfirmDialog";



const initialFValues = {
    Item_Name: '',
    System_ID: '',
    Item_Code:'',
    Item_Price: '',
}

export default function ItemForm(props) {
    const { addOrEdit, recordForEdit } = props
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('Item_Name' in fieldValues)
            temp.Item_Name = fieldValues.Item_Name ? "" : "Name is not valid"
        if ('System_ID' in fieldValues)
            temp.System_ID = fieldValues.System_ID ? "" : "ID is not valid"
        if ('Item_Code' in fieldValues)
            temp.Item_Code = fieldValues.Item_Code ? "" : "Code is not valid."
        if ('Item_Price' in fieldValues)
            temp.Item_Price = fieldValues.Item_Price.length != 0 ? "" : "Price is not valid."
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }
    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            },console.log(initialFValues))
            
            console.log(recordForEdit)
    }, [recordForEdit])

    const onAddOrUdpateItem = () => {
       
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        handleSubmit()
    }
    
    const onResetForm = () => {
       
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
       resetForm()
    }
    return (
        <>
        <Form  onSubmit={(e) => {
            e.preventDefault()
                                setConfirmDialog({
                                isOpen: true,
                                title: 'Are you sure to Submit',
                                subTitle: "You can't undo this operation",
                                onConfirm: () => { onAddOrUdpateItem() }
                                })
                            }}>
            <Grid container>
                <Grid item xs={12}>
                    <Controls.Input
                        name="Item_Name"
                        label="Item Name"
                        value={values.Item_Name}
                        onChange={handleInputChange}
                        error={errors.Item_Name}
                    />
                    <Controls.Input
                        label="System ID"
                        name="System_ID"
                        value={values.System_ID}
                        onChange={handleInputChange}
                        error={errors.System_ID}
                    />
                    <Controls.Input
                        label="Item Code"
                        name="Item_Code"
                        value={values.Item_Code}
                        onChange={handleInputChange}
                        error={errors.Item_Code}
                    />
                    <Controls.Input
                        name="Item_Price"
                        label="Item Price"
                        value={values.Item_Price}
                        onChange={handleInputChange}
                        error={errors.Item_Price}
                    />

                    <div style={{margin:".8rem"}}>
                        <Controls.Button
                        keyupfire={false}
                            type="submit"
                            text="Submit" />
                        <Controls.Button
                        keyupfire={false}
                            text="Reset"
                            color="default"
                            onClick={() => {
                                setConfirmDialog({
                                isOpen: true,
                                title: 'Are you sure to reset',
                                subTitle: "You can't undo this operation",
                                onConfirm: () => { onResetForm() }
                                })
                                }}/>
                    </div>
                </Grid>
            </Grid>
        </Form>
        <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
    />
    </>
    )
}
