import {GETCUSTOMERS,UPDATECUSTOMER,DELETECUSTOMER,EMPTYCUSTOMERS} from './types'
import axios from 'axios'

export const GetCustomers =(data,setLoading)=>dispatch=>{
    const headers={
        "x-access-token": sessionStorage.getItem("accountingauthkey")
    }
    axios.post('/api/getcustomers',data,{headers:headers}).then(res=>{console.log(res) 
      setLoading(false)
    dispatch({type:GETCUSTOMERS,payload:res.data})})
}

export const EmptyCustomer =()=>dispatch=>{
  dispatch({type:EMPTYCUSTOMERS})
}
export const UpdateCustomer=(customer)=>dispatch=>{
  const headers={
      "x-access-token": sessionStorage.getItem("accountingauthkey")
  }
  axios('/api/updatecustomer',customer,headers).then(res=>dispatch({type:UPDATECUSTOMER,payload:res.message}))
}

export const DeleteCustomer =(customerID,totalPages,totalCustomers)=>(
  {type:DELETECUSTOMER,
  payload:{customerID,totalPages,totalCustomers}}
)