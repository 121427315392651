import React from 'react'
import { Paper, Card, Typography, makeStyles,Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#ffffff',
        width:"100%",
        minHeight:"8rem",
        display:"flex",
        alignItems:"center",
        marginBottom:"1rem",
        paddingRight:"1rem",

        [theme.breakpoints.down(600)]:{
            paddingLeft:"1rem",
            paddingTop:"1rem",
            paddingRight:"0rem",
        },
        "& .MuiGrid-item > *":{
            maxWidth: `calc(100% - 1rem) !important`,
            width: `calc(100% - 1rem) !important`,
            marginRight: `1rem !important`
       },
       
    },
    pageHeader:{
  
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between"

    },
    pageIcon:{
        display:'inline-block',
        color:theme.palette.primary.main,
        padding: ".5rem"
    },
    pageInnerContainer:{
        display:"flex",
        paddingLeft:"1rem",
        alignItems:"center",
        "& svg":{
            fontSize:"3rem"
        },
        [theme.breakpoints.down('sm')]:{
            display:"none"
        }
    },
    pageTitle:{

        '& .MuiTypography-subtitle2':{
            opacity:'0.6'
        },
        "& .MuiTypography-h6":{
            fontSize:"2rem"
        }
        
    },
    mainTeading:{
        color:theme.palette.primary.text
    }
}))

export default function PageHeader({ title, subTitle, icon,children }) {

    const classes = useStyles();
   
    return (
        <Paper   className={classes.root}>
            <Grid container className={classes.pageHeader}>
                <Grid item className={classes.pageInnerContainer}  lg={2} md={2} sm={2}>
                <Grid container  lg={2} md={2} sm={2}>
                    <Card className={classes.pageIcon}>
                    {icon}
                    </Card>
                    </Grid>
                    <Grid container   lg={2} md={2} sm={2}>
                <div className={classes.pageTitle}>
                    <Typography
                    className={classes.mainTeading}
                        variant="h6"
                        component="div">
                        {title}</Typography>
                    <Typography  
                        variant="subtitle2"
                        component="div">
                        {subTitle}</Typography>
                        </div>
                        </Grid>
                </Grid>
                {children}
            </Grid>
        </Paper>
    )
}
