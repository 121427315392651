import {combineReducers} from 'redux'
import Auth from "./reducers/authReducer"
import User from "./reducers/UserReducer"
import orderItemsList from "./reducers/orderItemsListReducer"
import OrdersList from "./reducers/ordersListReducer"
import ItemsList from "./reducers/itemsReducer"
import CustomerList from "./reducers/CustomersReducer"
import AreasList from "./reducers/AreasReducer"
import UnitsList from "./reducers/UnitsReducer"
export default combineReducers({
    Auth:Auth,
    User:User,
    orderItemsList:orderItemsList,
    OrdersList:OrdersList,
    ItemsList:ItemsList,
    CustomerList:CustomerList,
    AreasList,AreasList,
    UnitsList,UnitsList
})