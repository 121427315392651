import React, { useEffect, useState } from 'react'
import {useLocation} from 'react-router-dom'
import {DeleteForever,Edit, NoteAdd,Search} from '@material-ui/icons';
import { Paper, Toolbar,makeStyles, Grid,TableBody, TableRow,TableCell,Typography,InputAdornment} from '@material-ui/core';
import Controls from "../../components/controls/Controls";
import {connect} from 'react-redux'
import 'jspdf-autotable';
import $ from "jquery";
import Button from '../../components/controls/Button';
import printJS from 'print-js'


let myheight = window.innerHeight
const useStyles = makeStyles(theme => ({
    pageContent: {
        padding: theme.spacing(3,3,3,3),
        height: `calc(${myheight}px - 90px)`,
        // [theme.breakpoints.down('md')]:{
        //     maxHeight: "53rem",
        // },
        [theme.breakpoints.down('xs')]:{
            height: `calc(${myheight}px - 58px)`,
        },
        overflowX: "auto",
       "& .MuiGrid-item > *":{
       
            maxWidth: `calc(100% - 1rem) !important`,
            width: `calc(100% - 1rem) !important`,
            marginRight: `1rem !important`
       },
       "& .table-container":{
        height: `calc(${myheight}px - 410px)`,
        [theme.breakpoints.between('md',"xl")]:{
            height: `calc(${myheight}px - 400px)`
        },
        [theme.breakpoints.only('xs')]:{
            height: `calc(${myheight}px - 573px)`
        },
        overFlow:"auto",
        },
        "& .MuiToolbar-gutters":{
            paddingLeft: "1rem"
            }
    },
    newButton:{
      marginTop:"1.6rem",
      bottom:0,
      height: "3.7rem",
      margin:0,
      padding:0,
    },
    
    
}))


const OrderInvoice = (props)=> {
  const classes = useStyles()
    const [values,setValues] = useState({Order_ID:null,Order_Date:"",Customer_ID:"",Customer_Name:"",Customer_Mobile:"",Customer_Address:"",Order_Reference:"",Order_Amount:"00.00"})
    const location = useLocation()   
    
    useEffect(()=>{
        if(location.state){
        setValues({Order_ID:location.state.order.Order_ID,Customer_ID:location.state.order.Customer_ID,Customer_Name:location.state.order.Customer_Name,Customer_Mobile:location.state.order.Customer_Mobile,Customer_Address:location.state.order.Customer_Address,Order_Date:location.state.order.Order_Date,Order_Reference:location.state.order.Order_Reference,Order_Amount:location.state.order.Order_Amount,Order_Date:location.state.order.Order_Date})
        console.log(location.state,props.orderItemsList)
        }
    },[location.state])

    
    let arrayItems 
    arrayItems=props.orderItemsList.map(item=>{
        let html =<tr>
      <td style={{border: "1px solid #dddddd", textAlign: "left"}}>{item.Item_ID}</td>
        <td style={{border: "1px solid #dddddd", textAlign: "left"}}>{item.Item_Name}</td>
        <td style={{border: "1px solid #dddddd", textAlign: "left"}}>{item.Item_Code}</td>
        <td style={{border: "1px solid #dddddd", textAlign: "left"}}>{item.Order_Item_Price}</td>
        <td style={{border: "1px solid #dddddd", textAlign: "left"}}>{item.Order_Item_Qty}</td>
        <td style={{border: "1px solid #dddddd", textAlign: "left"}}>{item.Order_Item_Amount}</td>
      </tr>
      
        return( html)
    })
    const PrinTInvoice=()=>{
    printJS({
        printable:"order-invoice",
        type:"html",
        targetStyles:['*']
    })
    }
    return (
        

<Paper className={classes.pageContent}> 
    <div id="order-invoice" class="invoice-box">
    <div class="details-div">
      <div className="heading-div">
      <h1 class="Main-Heading">INVOICE</h1>
      <div class="sub-Heading">
        <h3 class="date"><label>Date:</label>&nbsp;{values.Order_Date.substr(0,10)}</h3>
        <h3><label>Reference:</label>&nbsp;{values.Order_Reference}</h3>
        </div>
      </div>
      <div class="inner-wrapper">
      <div class="customer-div">
        <h6><label>Customer Name:</label>&nbsp;{values.Customer_Name}</h6>
        <h6><label>Address:</label>&nbsp;{values.Customer_Address}</h6>
        <h6><label>Mobile:</label>&nbsp;{values.Customer_Mobile}</h6>
        </div>
        <div class="order-div">
      <h6><label>Order ID:</label>&nbsp;{values.Order_ID}</h6>
      <h6><label>Reference:</label>&nbsp;{values.Order_Reference}</h6>
    </div>
      </div>
      
</div>
    
      <table class="item-table" >
      <tr>
        <th >Item ID</th>
        <th >Name</th>
        <th >Code</th>
        <th >Price</th>
        <th > QTY</th>
        <th >Amount</th>
      </tr>
      {arrayItems}
      <tr>
        <td ></td>
        <td ></td>
        <td ></td>
        <td ></td>
        <td style={{fontSize:"1rem", color: "#003366",fontwWeight:"bolder"}} >TOTAL</td>
        <td style={{fontSize:"1rem", color: "#003366", fontWeight:"bolder"}}>{values.Order_Amount}</td>
      </tr>
      </table>
      </div>
              <Typography className="signature" variant="h6">Signature________________________</Typography>

            
      <Grid item lg={1} md={1} sm={2} xs={3}>
                        <Controls.Button 
                        text="Print"
                        height="4rem"
                        id="button_Order_Add_Button"
                        nextid="input_Order_Item_Name"
                        onClick={window.print}
                        className={classes.newButton}
                 
                         >
                        </Controls.Button>
                        </Grid>
            </Paper>

        
        
    )
    
}

const mapStateToProps = (state) => ({

    orderItemsList: state.orderItemsList.Order_Items_List,

})

  export default connect(mapStateToProps)(OrderInvoice)

