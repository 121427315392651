import {deleteArea} from '../utils/AreaUtls'
const initilastate ={
    Areas_List:[],
    totalPages:"",
    currentPage:"",
    totalAreas:"",
    }

    const AreasList =(state = initilastate,Action)=>{
        switch (Action.type) {
            case "GETAREAS":
                console.log(Action.payload)
                

                return{
                    ...state,
                    Areas_List:[...state.Areas_List,...Action.payload.Areas],
                    totalPages:Action.payload.totalPages,
                    currentPage:Action.payload.currentPage,
                    totalAreas:Action.payload.totalAreas,
                    }
            break;
            case "DELETEAREA":
                return{
                    ...state,
                    Areas_List:deleteArea(state.Areas_List,Action.payload.areaID),
                    totalAreas:Action.payload.totalAreas,
                    totalPages:Action.payload.totalPages,
                    }
            break;
            case "EMPTYAREAS":
                return{
                    ...state,
                    Areas_List: [],
                    totalPages:"",
                    currentPage:"",
                    totalAreas:"",
                    }
            break;
            default:
                return state
                break;
        }
    
    }
    
    export default AreasList
    
    

