import React,{useState} from 'react';
import { Grid,Paper, Avatar, TextField, Button, Typography } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import {SigninHandler} from "../../redux/actions/AuthAction"
import TransitionAlerts from "../common/Alert"
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import $ from 'jquery'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },

  },
  authLint:{
    color:theme.palette.primary.main
  },
  avatarStyle:{
    backgroundColor:theme.palette.primary.main
  }
}));
const Login =(props)=> {
  const classes = useStyles();
  const [state,setState]=useState({email:'',password:''})
  const [alert, setAlert] = useState({ isOpen: false, message: '', type: '' })
  const history = useHistory()
//jquery
  $( document ).ready(()=>{
    $(document).keydown((event)=>{
      if(event.keyCode === 13){
        $('#signInBtn').click()
      }
    })
});

  function onChange(event) {
    const { name, value } = event.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  const handleLogin = (event)=>{
    event.preventDefault()
    const headers = {
      'Content-Type': 'application/json'
    }
    const data = JSON.stringify(state)
  axios.post('/auth/login',data,{
      headers: headers
    }).then(res=>{
      if (res.data.token) {
        console.log(res.data)
        props.signinhandle(res.data)
        history.push('/vieworders')
      } else {
        setAlert({
          isOpen: true,
          message: res.data.message,
          type: res.data.iserror ? 'error':'success'
      })
      }
     
    }).catch(err=>{
      setAlert({
        isOpen: true,
        message: err.response.data.message,
        type: err.response.data.iserror ? 'error':'success'
    })
    })
    
  }
 

 const paperStyle={padding :20,minHeight:'35rem',width:280, margin:"20px auto"}
 const avatarStyle={backgroundColor:'#1bbd7e'}
 const btnstyle={margin:'8px 0'}
  return ( <>
    <Grid container>
    <Grid item style={{margin:"20px auto"}} xs={8}  sm={6} lg={4}><TransitionAlerts message={alert.message} open={alert.isOpen} setOpen={()=>setAlert({...alert,isOpen:false})} type={alert.type} reopen={false}/></Grid>

        </Grid>
    <Paper elevation={10} style={paperStyle}>
        <Grid align='center'>
             <Avatar className={classes.avatarStyle}><LockOutlinedIcon/></Avatar>
            <h2>Sign In</h2>
        </Grid>
        <TextField label='Email' name="email"
        value={state.email}
        onChange={onChange} placeholder='Email' fullWidth required/>
        <TextField label='Password' placeholder='Enter password'
        value={state.password}
        name="password"
        onChange={onChange} type='password' fullWidth required/>
        <Button id="signInBtn" onClick={handleLogin} color='primary' variant="contained" style={btnstyle} fullWidth>Sign In</Button>
        {/* <Typography > Do you have an account ?
             <Link className={classes.authLint} to="/register" >
                Register
        </Link>
        </Typography> */}
        <Typography > Do you have an account?&nbsp;
             <Link className={classes.authLint} to="/login" >
             Sign In as User
        </Link>
        </Typography>
        <Typography > Do you have an account?&nbsp;
             <Link className={classes.authLint} to="/forgetpassword" > 
             Forget Password?
        </Link>
        </Typography>
        
        <Grid  align='center' item lg={12}>
        <img  src={process.env.PUBLIC_URL + 'simple-solutions-logo.png'} />
        </Grid>
    </Paper>
    </>
  );
}

const mapDispatchToProps = dispatch=>({
  signinhandle : (data)=>{dispatch(SigninHandler(data))}
  
})
export default connect(null,mapDispatchToProps)(Login);
