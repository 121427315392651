exports.LZero=(num, size)=> {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}


exports.currency=(Amount)=>{
var DecimalSeparator = Number("1.2").toLocaleString().substr(1,1);

var AmountWithCommas = Amount.toLocaleString();
var arParts = String(AmountWithCommas).split(DecimalSeparator);
var intPart = arParts[0];
var decPart = (arParts.length > 1 ? arParts[1] : '');
decPart = (decPart + '00').substr(0,2);

return 'RS ' + intPart + DecimalSeparator + decPart;
}

exports.currencyOnlyDecimal=(Amount)=>{
  var DecimalSeparator = Number("1.2").toLocaleString().substr(1,1);
  
  var AmountWithCommas = Amount.toLocaleString();
  var arParts = String(AmountWithCommas).split(DecimalSeparator);
  var intPart = arParts[0];
  var decPart = (arParts.length > 1 ? arParts[1] : '');
  decPart = (decPart + '00').substr(0,2);
  
  return intPart + DecimalSeparator + decPart;
  }

exports.formatDate = (date) => {

const dateObj = new Date(date);
const month = String(dateObj.getMonth()+1).padStart(2, '0');
const day = String(dateObj.getDate()).padStart(2, '0');
const year = dateObj.getFullYear();
const output =  day  + '-'+  month + '-' + year;

return output

      
    };
    exports.formatDateyearmonthday = (date) => {

      const dateObj = new Date(date);
      const month = String(dateObj.getMonth()+1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');
      const year = dateObj.getFullYear();
      const output =  year  + '-'+  month + '-' + day;
      
      return output
      
            
          };

exports.capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }



// export to excel
exports.exportTableToExcel=(tableID, filename = '')=>{
  var downloadLink;
  var dataType = 'application/vnd.ms-excel';
  var tableSelect = document.getElementById(tableID);
  var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
  
  // Specify file name
  filename = filename?filename+'.xls':'excel_data.xls';
  
  // Create download link element
  downloadLink = document.createElement("a");
  
  document.body.appendChild(downloadLink);
  
  if(navigator.msSaveOrOpenBlob){
      var blob = new Blob(['\ufeff', tableHTML], {
          type: dataType
      });
      navigator.msSaveOrOpenBlob( blob, filename);
  }else{
      // Create a link to the file
      downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
  
      // Setting the file name
      downloadLink.download = filename;
      
      //triggering the function
      downloadLink.click();
  }
}