import React, { useEffect ,useState} from 'react'
import { Grid, } from '@material-ui/core';
import Controls from "../../components/controls/Controls";
import { useForm, Form } from '../../components/useForm';
import ConfirmDialog from "../../components/ConfirmDialog";



const initialFValues = {
    Customer_Name: '',
    Account_Number: '',
    Customer_Address:'',
    Customer_Mobile: '',
    Area_ID:''
} 

export default function CustomerForm(props) {
    const { addOrEdit, recordForEdit } = props
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })


    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('Customer_Name' in fieldValues)
            temp.Customer_Name = fieldValues.Customer_Name ? "" : "Customer Name is not valid."
        if ('Account_Number' in fieldValues)
            temp.Account_Number = fieldValues.Account_Number ? "" : " Account Number is not valid."
        if ('Customer_Address' in fieldValues)
            temp.Customer_Address = fieldValues.Customer_Address ? "" : "Address is not valid."
        if ('Customer_Mobile' in fieldValues)
            temp.Customer_Mobile = fieldValues.Customer_Mobile.length != 0 ? "" : " Customer Mobile is not valid."
        setErrors({
            ...temp
        })
        if ('Area_ID' in fieldValues)
            temp.Area_ID = fieldValues.Area_ID.length != 0 ? "" : "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = () => {
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }
    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            },console.log(initialFValues))
            
            console.log(recordForEdit)
    }, [recordForEdit])
    const onAddOrUdpateCustomer = () => {
       
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        handleSubmit()
    }
    
    const onResetForm = () => {
       
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
       resetForm()
    }
    return (
        <>
        <Form onSubmit={(e) => {
            e.preventDefault()
                                setConfirmDialog({
                                isOpen: true,
                                title: 'Are you sure to Submit',
                                subTitle: "You can't undo this operation",
                                onConfirm: () => { onAddOrUdpateCustomer() }
                                })
                            }}>
            <Grid container>
                <Grid item xs={12}>
                    <Controls.Input
                        name="Customer_Name"
                        label="Customer Name"
                        value={values.Customer_Name}
                        onChange={handleInputChange}
                        error={errors.Customer_Name}
                    />
                    <Controls.Input
                        label="Account Number"
                        name="Account_Number"
                        value={values.Account_Number}
                        onChange={handleInputChange}
                        error={errors.Account_Number}
                    />
                    <Controls.Input
                        label="Customer Address"
                        name="Customer_Address"
                        value={values.Customer_Address}
                        onChange={handleInputChange}
                        error={errors.Customer_Address}
                    />
                    <Controls.Input
                        name="Customer_Mobile"
                        label="Customer Mobile"
                        value={values.Customer_Mobile}
                        onChange={handleInputChange}
                        error={errors.Customer_Mobile}
                    />
                    <Controls.Select
                        name="Area_ID"
                        label="Area"
                        value={values.Area_ID}
                        onChange={handleInputChange}
                        options={props.Areas_List.map(Areas_List_Area=>({id:Areas_List_Area.Area_ID,title:Areas_List_Area.Area_Name}))}
                        error={errors.Area_ID}
                    />

                    <div style={{margin:".8rem"}}>
                        <Controls.Button
                        keyupfire={false}
                            type="submit"
                            text="Submit" />
                        <Controls.Button
                        keyupfire={false}
                            text="Reset"
                            color="default"
                            onClick={() => {
                                setConfirmDialog({
                                isOpen: true,
                                title: 'Are you sure to reset',
                                subTitle: "You can't undo this operation",
                                onConfirm: () => { onResetForm() }
                                })
                                }}/>
                    </div>
                </Grid>
            </Grid>
        </Form>
        <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
    />
    </>
    )
}
