const initilastate ={
    userAuthKey: sessionStorage.getItem("accountingauthkey")?sessionStorage.getItem("accountingauthkey"):null,
    userName: sessionStorage.getItem("accountinguser")?sessionStorage.getItem("accountinguser"):'anonymus',
    userType: sessionStorage.getItem("accountingusertype")?sessionStorage.getItem("accountingusertype"):null
    }

    const Auth =(state = initilastate,Action)=>{
        switch (Action.type) {
            case "AUTHENTICATEUSER":
                console.log(Action.payload)
                sessionStorage.setItem("accountingauthkey",Action.payload.token)
                sessionStorage.setItem("accountinguser",Action.payload.user.userName)
                sessionStorage.setItem("accountingusertype",Action.payload.user.userType)
                return{
                    ...state,
                    userAuthKey: sessionStorage.getItem("accountingauthkey"),
                    userName: sessionStorage.getItem("accountinguser"),
                    userType: sessionStorage.getItem("accountingusertype")
                    }
            break;
            case "LOGUOTUSER":
                return{
                    ...state,
                    userAuthKey: sessionStorage.getItem("accountingauthkey"),
                    userName: sessionStorage.getItem("accountinguser"),
                    userType: sessionStorage.getItem("accountingusertype")
                    }
            break;
            default:
                return state
                break;
        }
    
    }
    
    export default Auth
    
    

