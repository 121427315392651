import React, { useState,useRef, useEffect } from 'react'
import CustomerForm from "./CustomerForm";
import PageHeader from "../../components/PageHeader";
import axios from 'axios'
import PeopleAlt from '@material-ui/icons/PeopleAlt';
import { Paper, makeStyles, TableBody, TableRow, TableCell, Toolbar, InputAdornment, Box,Grid, IconButton } from '@material-ui/core';
import useTable from "../../components/useTable";
import Controls from "../../components/controls/Controls";
import {connect} from 'react-redux'
import { Search } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import Print from '@material-ui/icons/Print';
import FileCopy from '@material-ui/icons/FileCopy';
import Popup from "../../components/Popup";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close'; 
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import {GetCustomers,EmptyCustomer,DeleteCustomer} from '../../redux/actions/CustomerAction'
import {GetAreas,EmptyAreas} from '../../redux/actions/AreasAction'
import jsPDF from 'jspdf';
import misc from '../../fnc/misc'
import 'jspdf-autotable';
const useStyles = makeStyles(theme => ({

    pageContent: {
        padding: "2rem 2rem 0 2rem",
        height: `calc(${window.innerHeight}px - 150px)`,
        [theme.breakpoints.up('md')]:{
            height: `calc(${window.innerHeight}px - 200px)`,
        },
        overflow: "hiddin",
        "& .MuiGrid-item > *":{
       
            maxWidth: `calc(100% - 1rem) !important`,
            width: `calc(100% - 1rem) !important`,
            marginRight: `1rem !important`
       },
       "& .table-container":{
        height: `calc(${window.innerHeight}px - 250px)`,
        overflow:"auto",
        "& [name=actions]":{
            "& svg":{
                visibility:"hidden"
            }
        },
        "& #scroll_Btn":{
            margin:"auto",display:"none",
            [theme.breakpoints.between("xs","sm")]:{
                display:"block"
            }
        }
    }
    },
    Toolbar:{
    },
    
    ToolbarItems:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        [theme.breakpoints.between("xs","sm")]:{
            
            paddingLeft:"1rem"
        },
    },
    
       
    buttonContainerGrid:{
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-end",
        [theme.breakpoints.between("xs","sm")]:{
            marginRight:"2rem",
        },
        "& button":{
           
            width:"32.333%",
            [theme.breakpoints.between("md","xl")]:{
                marginRight:".3rem",
            },
        },

 
  },
    newButtonMobile:{
        padding:0,
        margin:0,
        [theme.breakpoints.down('md')]: {
            display:"flex"
          },
          [theme.breakpoints.up('md')]: {
            display:"none"
          },
    },
    newButton:{
        height: "4rem",
        margin:0,
        [theme.breakpoints.down('md')]: {
            display:"none"
          },
          [theme.breakpoints.up('md')]: {
            display:"flex"
          },
    }
}))

const defaultProps = {
    bgcolor: 'background.paper',
    borderColor: 'inherit',
    m: 1,
    padding:0,
    border: 1,
    style: { width: '4rem', height: '4rem' },
    display:"flex",
    margin:"0rem",
    alignItems:"center",
    justifyContent:"center",
    
  };
const headCells = [
    { id: 'Customer_Name', label: 'Customer Name' },
    { id: 'Account_Number', label: 'Account Number',name:"id" },
    { id: 'Customer_Address', label: 'Customer Address' },
    { id: 'Customer_Mobile', label: 'Customer Mobile',name:"contactNumber" },
    { id: 'Area_Name', label: 'Area' },
    { id: 'actions', label: 'Actions',name:"actions" }
]

const Customers = (props)=> {

    const classes = useStyles();
    const [recordForEdit, setRecordForEdit] = useState(null)
    const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
    const [openPopup, setOpenPopup] = useState(false)
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const componentRef = useRef();
    const [loading, setLoading] = useState(false)
    const [searchItemName,setSearchItemName] =useState(null)
    const  [newAreas_List,setNewAreas_List] =useState([])
    
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
        rowsPerPage,
        page,
        setPage,
        handleScroll
    } = useTable(props.Customers_List, headCells, filterFn,componentRef,'medium',props.totalPages,props.currentPage,props.totalCustomers,props.getAllCustomers,props.EmptyCustomer,true,setLoading);
    const handleSearch = e => {
        let target = e.target;
        setFilterFn({
            fn: items => {
                if (target.value == "")
                    return items;
                else
                    return items.filter(x => `${x.Customer_Name}${x.Customer_Address}`.toLowerCase().includes(target.value)||`${x.Customer_Name}${x.Customer_Address}`.toUpperCase().includes(target.value)||  misc.capitalize(`${x.Customer_Name}${x.Customer_Address}`).includes(target.value))
            }
        })
    }

   
// generte pdf code
const generatePdf = ()=> {
var doc = new jsPDF();
doc.autoTable({
    html: '#table',
    theme:'grid',
    textColor:22
});
doc.save('table.pdf');
};


// generate pdf code
    const insertCustomer = (customer)=>{
        console.log('insert run')
        const headers={
          "x-access-token": sessionStorage.getItem("accountingauthkey")
      }
     axios.post('/api/addcustomer',customer,{headers:headers}).then(res=>{
         setNotify({
        isOpen: true,
        message: res.data.message,
        type: res.data.iserror ? 'error':'success'
    })
    
    if(res.data.iserror === false){
    props.EmptyCustomer()
    setPage(0)
    props.getAllCustomers({page:0,limit:rowsPerPage},setLoading)
    }
      }
      )
    }
    const updateCustomer = (customer)=>{
        console.log('update run')
        
        const Customer={
         Customer_Name:customer.Customer_Name,
         Account_Number:customer.Account_Number,
         Customer_Address:customer.Customer_Address,
         Customer_Mobile:customer.Customer_Mobile, 
         Area_ID:customer.Area_ID, 
         Customer_ID:customer.Customer_ID}
         console.log(Customer)
        const headers={
          "x-access-token": sessionStorage.getItem("accountingauthkey")
      }
     axios.post('/api/updatecustomer',Customer,{headers:headers}).then(res=>{
         setNotify({
        isOpen: true,
        message: res.data.message,
        type: res.data.iserror ? 'error':'success'
    })
    if(res.data.iserror === false){
        props.EmptyCustomer()
        setPage(0)
        props.getAllCustomers({page:0,limit:rowsPerPage},setLoading)
        }
     })
    }
    const addOrEdit = (customer, resetForm) => {
  
        if (!customer.Customer_ID){
          
           insertCustomer(customer)
        }
        else if( customer.Customer_ID ){
            updateCustomer(customer)
        }
        resetForm()
        setRecordForEdit(null)
        setOpenPopup(false)
    }


    const openInPopup = item => {
        setRecordForEdit(item)
        setOpenPopup(true)
    }
   const deleteCustomer=(CuntomerName,Customer_ID)=>{
    const headers={
      "x-access-token": sessionStorage.getItem("accountingauthkey")
  }
  console.log('delete run',Customer_ID)
 axios.post('/api/deletecustomer',{Customer_ID:Customer_ID},{headers:headers}).then(res=>{
        setNotify({
            isOpen: true,
            message: res.data.message,
            type: res.data.iserror ? 'error':'success'
        })
        if(res.data.iserror === false){
            props.EmptyCustomer()
            setPage(0)
            props.getAllCustomers({page:0,limit:rowsPerPage},setLoading)
            }
 }).catch(err=>{
    setNotify({
        isOpen: true,
        message: err.response.data.message,
        type: err.response.data.iserror ? 'error':'success'
    })
 })
    
   }
    const onDeleteCustomer = (CuntomerName,Customer_ID) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
       deleteCustomer(null,Customer_ID)
    }
    const changeSearchState = (e) => {
        const searchItemNameValue = e.target.value
      if (searchItemNameValue !== '') {
        setSearchItemName(searchItemNameValue)
      } else {
           setSearchItemName(null)
      }
        
    }



      useEffect(()=>{
          props.EmptyAreas()
        props.getAllAreas({page:0,limit:1212132313123232132},setLoading)
        // props.Areas_List.map(Areas_List_Area=>setNewAreas_List([...newAreas_List,{id:Areas_List_Area.Area_ID,title:Areas_List_Area.Area_Name}]))
        
      },[])
    return (
        <>
            <PageHeader 
                title="Customers"
                icon={<PeopleAlt color="primary" fontSize="large" />}
            >
           <Grid container  className={classes.ToolbarItems} lg={8} md={9} sm={12} xs={12} >
                <Grid container lg={5} md={5} sm={6} xs={6}   >
                    <Controls.Input
                        label="Search"
                        width="100%"
                        InputProps={{
                            startAdornment: (<InputAdornment position="start">
                                <Search />
                            </InputAdornment>)
                        }}
                        onChange={handleSearch}
                    />
                    </Grid>
                    <Grid className={classes.buttonContainerGrid}   container lg={7} md={7} sm={4} xs={5} >
                    
                    <IconButton
                        variant="outlined"
                        className={classes.newButtonMobile}
                        onClick={() => { setOpenPopup(true); setRecordForEdit(null); }}
                    ><Box borderRadius="50%" {...defaultProps}><AddIcon /></Box></IconButton>
                    <Controls.Button
                        text="Add New"
                        width="100%"
                        height="4rem"
                        startIcon={<AddIcon />}
                        className={classes.newButton}
                        onClick={() => { setOpenPopup(true); setRecordForEdit(null); }}
                    />
                
                    <IconButton
                        className={classes.newButtonMobile}
                        onClick={generatePdf}
                    ><Box borderRadius="50%" {...defaultProps}><Print /></Box></IconButton>
                    <Controls.Button
                        text="Print"
                        width="100%"
                        height="4rem"
                        startIcon={<Print/>}
                        className={classes.newButton}
                        onClick={generatePdf}
                    />

                    <IconButton
                        
                        className={classes.newButtonMobile}
                        onClick={()=>misc.exportTableToExcel('table')}
                    ><Box borderRadius="50%" {...defaultProps}><FileCopy /></Box></IconButton>
                    <Controls.Button
                        text="Excel"
                        width="100%"
                        height="4rem"
                        startIcon={<FileCopy/>}
                        className={classes.newButton}
                        onClick={()=>misc.exportTableToExcel('table')}
                    />
                    
                  
                    </Grid>
                </Grid>
            </PageHeader>
            <Paper elevation={7} className={classes.pageContent}>
            <div className="table-container" onScroll={handleScroll}>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {props.Customers_List !== null?(
                            recordsAfterPagingAndSorting().map(Customers_List_Customer =>
                                (<TableRow key={Customers_List_Customer.Customer_ID} >
                                    <TableCell  data-heading='Customer Name' >{Customers_List_Customer.Customer_Name}</TableCell>
                                    <TableCell name="id"  data-heading='Account Number' >{Customers_List_Customer.Account_Number}</TableCell>
                                    <TableCell  data-heading='Customer Address' >{Customers_List_Customer.Customer_Address}</TableCell>
                                    <TableCell name="contactNumber"  data-heading='Customer Mobile' >{Customers_List_Customer.Customer_Mobile}</TableCell>
                                    <TableCell  data-heading='Area' >{Customers_List_Customer.Area_Name}</TableCell>
                                    <TableCell  data-heading='Action' >
                                        <>
                                        <Controls.ActionButton
                                            colorPrimary="colorPrimary"
                                            onClick={() => { openInPopup(Customers_List_Customer) }}>
                                            <EditOutlinedIcon fontSize="small" />
                                        </Controls.ActionButton>
                                        <Controls.ActionButton
                                            colorPrimary="colorSecondary"
                                            onClick={() => {
                                                setConfirmDialog({
                                                isOpen: true,
                                                title: 'Are you sure to delete ?',
                                                subTitle: "You can't undo this operation",
                                                onConfirm: () => { onDeleteCustomer(null,Customers_List_Customer.Customer_ID) }
                                                })
                                                }}>
                                            <CloseIcon fontSize="small" />
                                        </Controls.ActionButton></>
                                    </TableCell>
                                </TableRow>)
                            )):(<Controls.Loader/>)
                        }
                        {page < props.totalPages?<Controls.Button id="scroll_Btn"  width="100%" height="4rem" text="Load" onClick={()=>page < props.totalPages ? setPage(page+1):null}></Controls.Button>:null}
                    </TableBody>
                    
                </TblContainer>
                {loading?<Controls.Loader/>:null}
                </div>
<table id="table" style={{width:" 100%",borderCollapse: "collapse",border: "1px solid black",display:"none"}} >
  <tr>
    {headCells.map((Customers_List_Customer,index)=> Customers_List_Customer.label !== "Actions"?<th key={index} style={{border: "1px solid black"}}>{Customers_List_Customer.label}</th>:null)}
  </tr>
  {props.Customers_List?recordsAfterPagingAndSorting().map((Customers_List_Customer,index)=>(
  <tr key={index}>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='Customer Name' >{Customers_List_Customer.Customer_Name}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='Account Number' >{Customers_List_Customer.Account_Number}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='Customer Address' >{Customers_List_Customer.Customer_Address}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='Customer Mobile' >{Customers_List_Customer.Customer_Mobile}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='Area' >{Customers_List_Customer.Area_Name}</td>
</tr>)):null}
</table>
            
            </Paper>
            <Popup
                title="Customer Form"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <CustomerForm
                Areas_List={props.Areas_List}
                    recordForEdit={recordForEdit}
                    addOrEdit={addOrEdit} />
            </Popup>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        
        </>
    )
}
const mapStateToProps=state=>({
    Areas_List: state.AreasList.Areas_List,
    Customers_List: state.CustomerList.Customers_List,
    totalPages:state.CustomerList.totalPages,
    currentPage:state.CustomerList.currentPage,
    totalCustomers:state.CustomerList.totalCustomers
  })
  const mapDispatchToProps = dispatch=>({
    EmptyAreas:()=>{dispatch(EmptyAreas())},
    getAllAreas : (data,setLoading)=>{dispatch(GetAreas(data,setLoading))},
    getAllCustomers : (data,setLoading)=>{dispatch(GetCustomers(data,setLoading))},
    EmptyCustomer:()=>{dispatch(EmptyCustomer())},
    DeleteCustomer:(Customer_ID,totalPages,totalCustomers)=>{dispatch(DeleteCustomer(Customer_ID,totalPages,totalCustomers))}
  })
  export default connect(mapStateToProps,mapDispatchToProps)(Customers)



