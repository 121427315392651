import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import Button from "./Button";
import ActionButton from "./ActionButton";
import Loader from "./Loader";
import DateInput from "./DateInput"
import SearchList from "./SearchList.js"
import SelectCustom from "./selectcustom"
const Controls = {
    Input,
    RadioGroup,
    Select, 
    Checkbox, 
    Button,
    ActionButton,
    Loader,
    DateInput,
    SearchList,
    SelectCustom
}

export default Controls;