import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Link,useHistory} from 'react-router-dom'
import { Grid,Paper, Avatar, TextField, Button, Typography } from '@material-ui/core'
import AccountBox from '@material-ui/icons/AccountBox';
import TransitionAlerts from "../common/Alert"
import axios from 'axios'
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },

  },
  authLint:{
    color:theme.palette.primary.main
  },
}));

export default function Register(props) {
  const classes = useStyles();
 const [state, setState] = useState({ userName:"",email: "",userFullName:"", password: "",confirmPassword:"" });
 const [open,setOpen] = useState(false)
 const [alert, setAlert] = useState({ isOpen: false, message: '', type: '' })
 const [message,setMessage ]= useState('')
 const history = useHistory()
  function onChange(event) {
    const { name, value } = event.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  }
  
 const onRegister=(event)=>{
    event.preventDefault()
    const headers = {
      'Content-Type': 'application/json',
    }
   const data= JSON.stringify(state)
    axios.post('/auth/register',data,{
      headers: headers
    }).then(res=>{
      if (res.data.iserror === false) {
        history.push('/admin')
      } else {
        setAlert({
          isOpen: true,
          message: res.data.message,
          type: res.data.iserror ? 'error':'success'
      })
      }
    }).catch(err=>{
      setAlert({
        isOpen: true,
        message: err.response.data.message,
        type: err.response.data.iserror ? 'error':'success'
    })
        console.log(err.response.data.message)
    })
}
  
const paperStyle={padding :20,minHeight:'35rem',width:280, margin:"20px auto"}
 const avatarStyle={backgroundColor:'#1bbd7e'}
 const btnstyle={margin:'8px 0'}
  return ( <>
    <Grid container>
    <Grid item style={{margin:"20px auto"}} xs={8}  sm={6} lg={4}><TransitionAlerts message={alert.message} open={alert.isOpen} setOpen={()=>setAlert({...alert,isOpen:false})} type={alert.type} reopen={false}/></Grid>

        </Grid>
        <Paper elevation={10} style={paperStyle}>
        <Grid align='center'>
             <Avatar style={avatarStyle}><AccountBox/></Avatar>
            <h2>Register</h2>
        </Grid>
        <TextField
        id="outlined-secondary"
        label="User Name"
        name="userName"
        value={state.userName}
        fullWidth
        required
        onChange={onChange}
      />
      <TextField
        id="outlined-secondary"
        label="Full Name"
        name="userFullName"
        value={state.userFullName}
        fullWidth
        required
        onChange={onChange}
      />
      <TextField
        id="outlined-secondary"
        label="Email"
        name="email"
        value={state.email}
        fullWidth
        required
        onChange={onChange}
      />
      <TextField
        id="outlined-secondary"
        label="Password"
        fullWidth
        required
        type="password"
        name="password"
        value={state.password}
        onChange={onChange}
      />
      <TextField
        id="outlined-secondary"
        label="confirm Password"
        fullWidth
        required
        type="password"
        name="confirmPassword"
        value={state.confirmPassword}
        onChange={onChange}
      />
        <Button onClick={onRegister} color='primary' variant="contained" style={btnstyle} fullWidth>Register</Button>
        <Typography > Aready have an account ?
             <Link className={classes.authLint} to="/admin" >
                Sign In as Admin
        </Link>
        </Typography>
        <Typography > Aready have an account ?
             <Link className={classes.authLint} to="/login" >
                Sign In as User
        </Link>
        </Typography>
    </Paper>
    </>
  );
}