import React from 'react'
import { Snackbar, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    root: {
        position:"fixed",
        width:"fit-content",
        top:"50%",
        left:"50%",
        transform: "translate(-50%, -50%)",
        fontSize:"3rem",
        "& .MuiAlert-icon":{
            paddingTop: 0,
            paddingBottom: 0,
            alignItems:"center",
            fontSize:"3rem",
            
        }
    }
}))

export default function Notification(props) {

    const { notify, setNotify } = props;
    const classes = useStyles()

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setNotify({
            ...notify,
            isOpen: false
        })
    }

    return (
        <Snackbar
            className={classes.root}
            open={notify.isOpen}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={handleClose}>
            <Alert
            style={{fontSize:"2rem"}}
                severity={notify.type}
                onClose={handleClose}>
                {notify.message}
            </Alert>
        </Snackbar>
    )
}
