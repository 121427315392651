import React, { useState,memo } from 'react'
import {useHistory,Link} from 'react-router-dom'
import PageHeader from "../../components/PageHeader";


import { Paper, makeStyles, TableBody, TableRow, TableCell, Toolbar, InputAdornment,Grid,IconButton,Box } from '@material-ui/core';
import useTable from "../../components/useTable";
import Controls from "../../components/controls/Controls";
import {connect} from 'react-redux'
import axios from 'axios';
import { saveAs } from 'file-saver';
import {EditOutlined,Close,Print,Search,Receipt,Add,FileCopy} from '@material-ui/icons';
import ConfirmDialog from "../../components/ConfirmDialog";
import Notification from "../../components/Notification";
import {GetOrders,emptyOrderList,DeleteOrderFromReduxState} from "../../redux/actions/ordersListAction"
import {setItems } from "../../redux/actions/orderItemsAction"
import misc from '../../fnc/misc'
import jsPDF from 'jspdf';

const innerHeight =window.innerHeight
const innerWidth =window.innerWidth
const useStyles = makeStyles(theme => ({
    pageContent: {
        padding: "2rem 2rem 0 2rem",
        height: `calc(${innerHeight}px - 150px)`,
        [theme.breakpoints.up('md')]:{
            height: `calc(${innerHeight}px - 200px)`,
        },
        overflow: "hiddin",
        "& .MuiGrid-item > *":{
       
            maxWidth: `calc(100% - 1rem) !important`,
            width: `calc(100% - 1rem) !important`,
            marginRight: `1rem !important`
       },
       "& .table-container":{
        height: `calc(${innerHeight}px - 250px)`,
        overflow:"auto",
        "& thead":{
            [theme.breakpoints.down(900)]:{
            display:"table-header-group",
            backgroundColor:"#739FDF",
            width:"100%",
            
            "&  tr" :{
                display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    padding: "0rem .2rem 0rem .2rem",
                    backgroundColor:"#739FDF",
                    "& [name=id]":{
                        width:"10% !important",
                        textAlign:"center"
                    },
                    "& [name=name]":{
                        width:"50% !important"
                    },
                    
                    "& [name=amount]":{
                        width:"20% !important",
                        textAlign:'right'
                    },
                    "& [name=actionsbtn]":{
                        width:"20% !important",
                        textAlign:'right',
                        "& svg":{
                            visibility:"hidden"
                        }
                    },
              "& th":{
                //   width:"1fr",
                color: "inherit",
                padding: "0",
                backgroundColor:"#739FDF",
                textAlign: "left",
                fontSize:"1rem",
                
              }
              }
            }
        },
        "& tbody":{
            
            [theme.breakpoints.down(900)]:{
                
                "& tr":{
                    paddingLeft:".5rem",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    padding: "0rem .2rem 0rem .2rem",
                    "& [name=id]":{
                        width:"10% !important",
                        textAlign:"center"
                    },
                    "& [name=name]":{
                        width:"50% !important",
                        
                    },
                    "& [name=Date]":{
                        display:"none"
                    },
                    "& [name=reference]":{
                        display:"none"
                    },
                    "& [name=contactNumber]":{
                        display:"none"
                    },
                    "& [name=address]":{
                        display:"none"
                    },
                    "& [name=area]":{
                        display:"none"
                    },
                    
                    "& [name=amount]":{
                        width:"20% !important",
                        textAlign:'right'
                    },
                    "& [name=actionsbtn]":{
                        width:"20% !important",
                        textAlign:'right'
                    },
                    
                
                },
                "& td":{
                    // width:"1fr",
                    display: "inline",
                    position: "static",
                    padding: "0",
                    textAlign: "left",
                    borderBottom: 0,
                    marginTop : "0rem",
                    "&::before":{
                        display:"none !important"
                    }
                },
            }

        },
        "& #scroll_Btn":{
            margin:"auto",display:"none",
            [theme.breakpoints.between("xs","sm")]:{
                display:"block"
            }
        }
    }
    },
   ToolbarItems:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        [theme.breakpoints.between("xs","sm")]:{
            
            paddingLeft:"1rem"
        },
    },
    buttonContainerGrid:{
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-end",
        [theme.breakpoints.between("xs","sm")]:{
            marginRight:"2rem",
        },
        "& button":{
           
            width:"32.333%",
            [theme.breakpoints.between("md","xl")]:{
                marginRight:".3rem",
            },
        },

 
  },
    newButtonMobile:{
        padding:0,
        [theme.breakpoints.down('md')]: {
            display:"flex"
          },
          [theme.breakpoints.up('md')]: {
            display:"none"
          },
    },
    newButton:{
        height: "4rem",
        margin:0,
        [theme.breakpoints.down('md')]: {
            display:"none"
          },
          [theme.breakpoints.up('md')]: {
            display:"flex"
          },
    },
    ActionBtnDiv:{
        [theme.breakpoints.down(900)]:{
            // display:"flex !important",
            // flexDirection:"column"
            "& > *":{
                width:"2rem",
                height:"2rem",
                padding:".5rem"
            },
            "& svg":{
                fontSize:"1rem"
            }
        }
    }
}))

const defaultProps = {
    bgcolor: 'background.paper',
    borderColor: 'inherit',
    m: 1,
    padding:0,
    border: 1,
    style: { width: '4rem', height: '4rem' },
    display:"flex",
    margin:"0rem",
    alignItems:"center",
    justifyContent:"center",
    
  };
  let headCells
  innerWidth >= 900 ? headCells = [
    { id: 'Order_ID', label: 'ID',name:"id" },
    { id: 'Customer_Name', label: 'Customer Name' },
    { id: 'Order_Date', label: 'Date',name:"Date" },
    { id: 'Order_Reference', label: 'Reference' },
    { id: 'Customer_Mobile', label: 'Contact',name:"contactNumber" },
    { id: 'Customer_Address', label: 'Address' },
    { id: 'Area', label: 'Area' },
    {id: 'Order_Amount', label: 'Total',name:"amount" },
    { id: 'actions', label: 'Actions', name:'actions' }
]:headCells=[
    { id: 'Order_ID', label: 'ID',name:"id" },
    { id: 'Customer_Name', label: 'Customer Name',name:"name" },
    {id: 'Order_Amount', label: 'Total',name:"amount" },
    { id: 'actions', label: 'Actions', name:'actions',name:"actionsbtn" }
]

const ViewOrders = (props)=> {

    const classes = useStyles();
    const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const history = useHistory()
    const [loading, setLoading] = useState(false);

    const getOrders =(data)=>{
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
          };
          axios.post('/api/getorders',data,options).then(res=>{
          
      props.GetOrders(res.data)
      setLoading(false)
      if(res.data.iserror){ setNotify({
        isOpen: true,
        message: res.data.message,
        type: res.data.iserror ? 'error':'success'
    })
    }

       })
      }
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
        rowsPerPage,
        page,
        setPage,
        handleScroll
    } = useTable(props.Order_List, headCells, filterFn,null,'medium',props.totalPages,props.currentPage,props.totalOrders,getOrders,props.emptyOrderList,true,setLoading);
                
    const handleSearch = e => {
        let target = e.target;
        setFilterFn({
            fn: orders => {
                if (target.value == "")
                    return orders;
                else
                    return orders.filter(x => `${x.Order_ID} ${x.Customer_Name}${x.Area_Name}${x.Order_Reference}${String.prototype.toString(x.Order_Date)}${x.Order_Amount}${x.Customer_Mobile}`.toLowerCase().includes(target.value) || `${x.Order_ID} ${x.Customer_Name}${x.Area_Name}${x.Order_Reference}${x.Order_Date}${x.Order_Amount}${x.Customer_Mobile}`.toUpperCase().includes(target.value)|| misc.capitalize(`${x.Order_ID} ${x.Customer_Name}${x.Area_Name}${x.Order_Reference}${x.Order_Date}${x.Order_Amount}${x.Customer_Mobile}`).includes(target.value))
            }
        })
    }

    const createAndDownloadPdf = (OrderID) => {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
          };
        axios.post('/api/generateorderbill',{OrderID:OrderID},options )
          .then(() => axios.get('/api/orderpdf', { responseType: 'blob' }))
          .then((res) => {
            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });

            saveAs(pdfBlob, `Order${OrderID}.pdf`);

          }).catch((err)=>{
            console.log(err)
        })
      }

     
      const deleteOrderl =(OrderName,OrderID)=>{
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
          };
       axios.post("/api/deleteorder",{OrderID:OrderID,limit:rowsPerPage},options).then(res=>{
           setNotify({
          isOpen: true,
          message: res.data.message,
          type: res.data.iserror ? 'error':'success'
      })
      if(res.data.iserror === false){
          props.emptyOrderList()
          setPage(0)
          getOrders({page:0,limit:rowsPerPage})
      }
       })
      }


      // generte pdf code
const generatePdf = ()=> {
    var doc = new jsPDF();
    doc.autoTable({
        html: '#table',
        theme:'grid',
        textColor:22
    });
    doc.save('table.pdf');
    };



      const getOderItems =(OrderID,order,invoice)=>{
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
          };
       axios.post("/api/getorderitems",{OrderID:OrderID},options).then(res=>{
           props.setItems(res.data.items)
      if(res.data.iserror === false && invoice === false){
        history.push('/addorder',{order:order})
      }else if(res.data.iserror === false && invoice === true){
        history.push('/orderinvoice',{order:order})
      }

       })
      }

      const onDeleteOrder = (OrderName,OrderID) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        deleteOrderl(OrderName,OrderID);
    }
    

    return (
        <>
            <PageHeader 
                title="Orders"
                icon={<Receipt color="primary" fontSize="large" />}
            >
<Grid container  className={classes.ToolbarItems} lg={8} md={9} sm={12} xs={12} >
                <Grid container lg={5} md={5} sm={6} xs={6}   >
                    <Controls.Input
                        label="Search"
                        width="100%"
                        InputProps={{
                            startAdornment: (<InputAdornment position="start">
                                <Search />
                            </InputAdornment>)
                        }}
                        onChange={handleSearch}
                    />
                    </Grid>
                    <Grid className={classes.buttonContainerGrid}   container lg={7} md={7} sm={4} xs={5} >
                    
                    <IconButton
                    focusRipple={false}
                    TouchRippleProps={false}
                    disableRipple={true}
                    className={classes.newButtonMobile}
                    variant="outlined"
                      
                    ><Link to="addorder"   ><Box borderRadius="50%" {...defaultProps}><Add /></Box></Link></IconButton>
                    
                    <Controls.Button
                        text="Add New"
                        width="100%"
                        height="4rem"
                        onClick={()=>history.push('/addorder')}
                        className={classes.newButton}
                        startIcon={<Add />}    
                        > </Controls.Button>
                    
        
                    <IconButton
                        className={classes.newButtonMobile}
                        onClick={generatePdf}
                    ><Box borderRadius="50%" {...defaultProps}><Print /></Box></IconButton>
                    <Controls.Button
                        text="Print"
                        width="100%"
                        height="4rem"
                        startIcon={<Print/>}
                        className={classes.newButton}
                        onClick={generatePdf}
                    />

              
                    <IconButton
                        
                        className={classes.newButtonMobile}
                        onClick={()=>misc.exportTableToExcel('table')}
                    ><Box borderRadius="50%" {...defaultProps}><FileCopy /></Box></IconButton>
                    <Controls.Button
                        text="Excel"
                        width="100%"
                        height="4rem"
                        startIcon={<FileCopy/>}
                        className={classes.newButton}
                        onClick={()=>misc.exportTableToExcel('table')}
                    />
                    
               
                    </Grid>
                </Grid>
            </PageHeader>
            <Paper className={classes.pageContent} elevation={7}>
            <div className="table-container" onScroll={handleScroll}>
                <TblContainer >
                    <TblHead  />
                    <TableBody>
                        {props.Order_List !== null?(
                            recordsAfterPagingAndSorting().map(OrdersList_Order =>
                                (<TableRow key={OrdersList_Order.Order_ID} >
                                    <TableCell name="id" data-heading='Order ID' >{misc.LZero(OrdersList_Order.Order_ID,4)}</TableCell>
                                    <TableCell name="name"   data-heading='Customer Name' >{OrdersList_Order.Customer_Name}</TableCell>
                                    <TableCell name="Date"  data-heading='Date' >{misc.formatDate(OrdersList_Order.Order_Date).toString()}</TableCell>
                                    <TableCell name="reference"   data-heading='Reference' >{OrdersList_Order.Order_Reference}</TableCell>
                                    <TableCell name="contactNumber" data-heading='contact' >{OrdersList_Order.Customer_Mobile}</TableCell>
                                    <TableCell name="address"   data-heading='Address' >{OrdersList_Order.Customer_Address}</TableCell>
                                    <TableCell name="area"   data-heading='Area' >{OrdersList_Order.Area_Name}</TableCell>
                                    <TableCell  name="amount" data-heading='Total'>{OrdersList_Order.Order_Amount}</TableCell>
                                    <TableCell name="actionsbtn" className={classes.ActionBtnDiv}  data-heading='Action' >
                                        { OrdersList_Order.User_Type !== 1?(
                                        <>
                                        <Controls.ActionButton
                                            colorPrimary="colorPrimary"
                                            onClick={() =>getOderItems(OrdersList_Order.Order_ID,OrdersList_Order,false)}>
                                            <EditOutlined fontSize="small" />
                                        </Controls.ActionButton>
                                        <Controls.ActionButton
                                            colorPrimary="colorSecondary"
                                            onClick={() => {
                                                setConfirmDialog({
                                                isOpen: true,
                                                title: 'Are you sure to delete ?',
                                                subTitle: "You can't undo this operation",
                                                onConfirm: () => { onDeleteOrder(null,OrdersList_Order.Order_ID) }
                                                })
                                                }}>
                                            <Close fontSize="small" />
                                        </Controls.ActionButton>
                                        {/* <Controls.ActionButton
                                        onClick={()=>getOderItems(OrdersList_Order.Order_ID,OrdersList_Order,true)}
                                            color="text-primary"
                                            >
                                            <Print fontSize="small" />
                                        </Controls.ActionButton> */}
                                        </>):('')}
                                    </TableCell>
                                   
                                </TableRow>)
                            )):(<Controls.Loader/>)
                        }
                        {page < props.totalPages?<Controls.Button id="scroll_Btn"  width="100%" height="4rem" text="Load" onClick={()=>page < props.totalPages ? setPage(page+1):null}></Controls.Button>:null}
                    </TableBody>
                </TblContainer>
                {loading?<Controls.Loader/>:null}
                </div>
             
                <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
<table id="table" style={{width:" 100%",borderCollapse: "collapse",border: "1px solid black",display:"none"}} >
  <tr>
    {headCells.map((OrdersList_Order,index)=> OrdersList_Order.label !== "Actions"?<th key={index} style={{border: "1px solid black"}}>{OrdersList_Order.label}</th>:null)}
  </tr>
  {props.Order_List !== null?(recordsAfterPagingAndSorting().map((OrdersList_Order,index) =>
  (<tr key={index}>
          <td style={{border: "1px solid black",color:"black"}}  data-heading='ID' >{OrdersList_Order.Order_ID}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='Customer Name' >{OrdersList_Order.Customer_Name}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='Date' >{misc.formatDate(OrdersList_Order.Order_Date).toString()}</td>
    <td style={{border: "1px solid black",color:"black"}}   data-heading='Reference' >{OrdersList_Order.Order_Reference}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='contact' >{OrdersList_Order.Customer_Mobile}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='Address' >{OrdersList_Order.Customer_Address}</td>
    <td style={{border: "1px solid black",color:"black"}}   data-heading='Area' >{OrdersList_Order.Area_Name}</td>
    <td style={{border: "1px solid black",color:"black"}}  name="amount" >{misc.currency(OrdersList_Order.Order_Amount)}</td>
</tr>))):(null)}
</table>

            </Paper>
        
        </>
    )
}

const mapStateToProps = (state) => ({

    Order_List: state.OrdersList.Order_List,
    totalPages:state.OrdersList.totalPages,
    currentPage:state.OrdersList.currentPage,
    totalOrders:state.OrdersList.totalOrders

})

  const mapDispatchToProps = dispatch=>({
      GetOrders:(orders)=>{dispatch(GetOrders(orders))},
      setItems:(items)=>{dispatch(setItems(items))},
      DeleteOrderFromReduxState:(OrderID,totalOrders,totalPages)=>{dispatch(DeleteOrderFromReduxState(OrderID,totalOrders,totalPages))},
      emptyOrderList:()=>{dispatch(emptyOrderList())}
  })
  export default connect(mapStateToProps,mapDispatchToProps)(memo(ViewOrders))

