import React,{useState} from 'react';
import { Grid,Paper, Avatar, TextField, Button, Typography } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import {SigninHandler} from "../../redux/actions/AuthAction"
import TransitionAlerts from "../common/Alert"
import axios from 'axios'
import { useHistory,useParams } from 'react-router-dom';
import $ from 'jquery'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },

  },
  authLint:{
    color:theme.palette.primary.main
  },
  avatarStyle:{
    backgroundColor:theme.palette.primary.main
  }
}));
const ForgetPassword =(props)=> {
  const classes = useStyles();
  const [state,setState]=useState({password:''})
  const [open,setOpen] = useState(false)
  const [error,setError ]= useState('')
  const [message,setMessage ]= useState('')
  const history = useHistory()
  const {token} = useParams()

//jquery
  $( document ).ready(()=>{
    $(document).keydown((event)=>{
      if(event.keyCode === 13){
        $('#signInBtn').click()
      }
    })
});

  function onChange(event) {
    const { name, value } = event.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  const handleLogin = (event)=>{
    event.preventDefault()
    const headers = { 
      'Content-Type': 'application/json'
    }
    const data = JSON.stringify({password:state.password,token:token})
  axios.post('/auth/resetpassword',data,{
      headers: headers
    }).then(res=>{
        if(res.data.iserror===false){
        setTimeout(()=>{
            history.push("/admin")
        },1000)
    }
    setMessage(res.data.message)
        setError(res.data.iserror)
        setOpen(true)
        console.log(res.data.message)
    })
    
  }
 

 const paperStyle={padding :20,minHeight:'35rem',width:280, margin:"20px auto"}
 const avatarStyle={backgroundColor:'#1bbd7e'}
 const btnstyle={margin:'8px 0'}
  return ( <>
    <Grid container>
      <Grid item style={{margin:"20px auto"}} xs={8}  sm={6} lg={4}><TransitionAlerts message={message} error={error} open={open} setOpen={()=>setOpen(false)} reopen={false}/></Grid>

        </Grid>
    <Paper elevation={10} style={paperStyle}>
        <Grid align='center'>
             <Avatar className={classes.avatarStyle}><LockOutlinedIcon/></Avatar>
            <h2>Reset Password</h2>
        </Grid>
        <TextField
        id="outlined-secondary"
        label="New Password"
        fullWidth
        required
        type="password"
        name="password"
        value={state.password}
        onChange={onChange}
      />
        <Button id="signInBtn" onClick={handleLogin} color='primary' variant="contained" style={btnstyle} fullWidth>Reset password</Button>
        {/* <Typography > Do you have an account ?
             <Link className={classes.authLint} to="/register" >
                Register
        </Link>
        </Typography> */}
        <Typography > Do you have an account?&nbsp;
             <Link className={classes.authLint} to="/admin" >
             Sign In as Admin
        </Link>
        </Typography>
        
        <Grid  align='center' item lg={12}>
        <img  src={process.env.PUBLIC_URL + 'simple-solutions-logo.png'} />
        </Grid>
    </Paper>
    </>
  );
}


export default ForgetPassword
