import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HashLoader from "react-spinners/HashLoader";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems:"center",
    justifyContent:"center",
    width:"100%",
  },
  
}));

const Loader=()=> {
  const classes = useStyles();

  return <div className={classes.root} ><HashLoader size="40" color='#28528F'  /></div>
}
export default  Loader