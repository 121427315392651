import React, { useEffect,useState } from 'react'
import { Grid, } from '@material-ui/core';
import Controls from "../../components/controls/Controls";
import { useForm, Form } from '../../components/useForm';
import ConfirmDialog from "../../components/ConfirmDialog";



const initialFValues = {
    User_Full_Name: '',
    User_Name: '',
    User_Password_Normal:'',
    User_Type: '',
}

export default function UserForm(props) {
    const { addOrEdit, recordForEdit } = props
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('User_Full_Name' in fieldValues)
            temp.User_Full_Name = fieldValues.User_Full_Name ? "" : "User Full Name is not valid."
        if ('User_Name' in fieldValues)
            temp.User_Name = fieldValues.User_Name ? "" : "User Name is not valid."
        if ('User_Password_Normal' in fieldValues)
            temp.User_Password_Normal = fieldValues.User_Password_Normal ? "" : "password is not valid."
        if ('User_Type' in fieldValues)
            temp.User_Type = fieldValues.User_Type.length != 0 ? "" : " Type is not valid."
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = () => {
        
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }
    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            },console.log(initialFValues))
            
            console.log(recordForEdit)
    }, [recordForEdit])

    const onAddOrUdpateUser = () => {
       
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        handleSubmit()
    }
    
    const onResetForm = () => {
       
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
       resetForm()
    }
    return (
        <>
        <Form onSubmit={(e) => {
            e.preventDefault()
                                setConfirmDialog({
                                isOpen: true,
                                title: 'Are you sure to Submit',
                                subTitle: "You can't undo this operation",
                                onConfirm: () => { onAddOrUdpateUser() }
                                })
                            }}>
            <Grid container>
                <Grid item xs={12}>
                    <Controls.Input
                        name="User_Full_Name"
                        label="Full Name"
                        value={values.User_Full_Name}
                        onChange={handleInputChange}
                        error={errors.User_Full_Name}
                    />
                    <Controls.Input
                        label="User Name"
                        name="User_Name"
                        value={values.User_Name}
                        onChange={handleInputChange}
                        error={errors.User_Name}
                    />
                    <Controls.Input
                        label="Password"
                        name="User_Password_Normal"
                        value={values.User_Password_Normal}
                        onChange={handleInputChange}
                        error={errors.User_Password_Normal}
                    />
                    <Controls.Select
                        name="User_Type"
                        label="Type"
                        value={values.User_Type}
                        onChange={handleInputChange}
                        options={[{id:2,title:"Reader"},{id:3,title:"Data Entry Operator"},{id:4,title:"Cashier"}]}
                        error={errors.User_Type}
                    />

                    <div>
                        <Controls.Button
                        keyupfire={false}
                            type="submit"
                            text="Submit" />
                        <Controls.Button
                        keyupfire={false}
                            text="Reset"
                            color="default"
                            onClick={() => {
                                setConfirmDialog({
                                isOpen: true,
                                title: 'Are you sure to reset',
                                subTitle: "You can't undo this operation",
                                onConfirm: () => { onResetForm() }
                                })
                                }} />
                    </div>
                </Grid>
            </Grid>
        </Form>
        <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
    />
    </>
    )
}
