import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function TransitionAlerts({reopen,message,open,error,setOpen,type}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Alert
        severity={type?"error":"success"}
        color={type?"error":"success"}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={setOpen}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
         {message}
        </Alert>
      </Collapse>
      { reopen?<Button disabled={open} variant="outlined"onClick={() => {setOpen(true);}}>Re-open</Button>:null}
    </div>
  )}
 
  export default TransitionAlerts