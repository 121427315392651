import React, { useEffect } from 'react'
import {BrowserRouter,Route,Switch,Redirect} from 'react-router-dom'
import MiniDrawer from "./components/MiniDrawer";
import Login from "./components/Auth/Login"
import UserLogin from "./components/Auth/UserLogin"
import ForgetPassword from "./components/Auth/ForgetPassword"
import ResetPassword from "./components/Auth/ResetPassword"
import Users from './pages/Users/Users'
import Items from './pages/Items/Items'
import Areas from './pages/Areas/Areas'
import Units from './pages/Units/Units'
import Customers from './pages/Customers/Customers'
import AddOrder from './pages/Purchases/AddOrder'
import ViewOrders from './pages/Purchases/ViewOrders'
import OrderInvoice from './pages/Purchases/OrderInvoice'
import Register from "./components/Auth/Register"
import {connect} from 'react-redux'
import { createMuiTheme,ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#28528F',
      text:"#28666b"
    },
  },
});

function App(props) {

  return (
    <ThemeProvider theme={theme}>
    <div className="App">
      <BrowserRouter>
      <Switch>
        <Route exact path="/" render={()=>props.userAuth  ?(<Redirect to={"/vieworders"}/>):(<Redirect to={"/login"}/>)}/>
        <Route path="/login" render={()=>props.userAuth  ?(<Redirect to={"/vieworders"}/>):(<UserLogin/>)}/>
        <Route path="/forgetpassword" render={()=>props.userAuth  ?(<Redirect to={"/vieworders"}/>):(<ForgetPassword/>)}/>
        <Route path="/resetpassword/:token" render={()=>props.userAuth  ?(<Redirect to={"/vieworders"}/>):(<ResetPassword/>)}/>
        <Route path="/admin" render={()=>props.userAuth  ?(<Redirect to={"/vieworders"}/>):(<Login/>)}/>
        <Route path="/register"  render={()=>props.userAuth  ?(<Redirect to={"/vieworders"}/>):(<Register/>)}/>
        <Route path="/users"  render={()=>props.userAuth && props.userType ==='Administrator'  ?(<MiniDrawer><Users/></MiniDrawer>):(props.userAuth?<Redirect to={"/vieworders"}/>:<Redirect to={"/login"}/>)}/>
        {/* <Route path="/vieworders" render={()=>props.userAuth  ?(<MiniDrawer></MiniDrawer>):(<Redirect to={"/login"} />)}/> */}
        <Route path="/addorder" render={()=>props.userAuth  ?(<MiniDrawer><AddOrder/></MiniDrawer>):(<Redirect to={"/login"} />)}/>
        <Route path="/vieworders" render={()=>props.userAuth  ?(<MiniDrawer><ViewOrders/></MiniDrawer>):(<Redirect to={"/login"} />)}/>
        <Route path="/orderinvoice" render={()=>props.userAuth  ?(<MiniDrawer><OrderInvoice/></MiniDrawer>):(<Redirect to={"/login"} />)}/>
        <Route path="/areas" render={()=>props.userAuth  ?(<MiniDrawer><Areas/></MiniDrawer>):(<Redirect to={"/login"} />)}/>
        <Route path="/units" render={()=>props.userAuth  ?(<MiniDrawer><Units/></MiniDrawer>):(<Redirect to={"/login"} />)}/>
        <Route path="/items" render={()=>props.userAuth  ?(<MiniDrawer><Items/></MiniDrawer>):(<Redirect to={"/login"} />)}/>
        <Route path="/customers" render={()=>props.userAuth  ?(<MiniDrawer><Customers/></MiniDrawer>):(<Redirect to={"/login"} />)}/>
      </Switch>
      </BrowserRouter>
    </div> 
    </ThemeProvider>
  );
}
const mapStateToProps=state=>({
  userAuth: state.Auth.userAuthKey,
  userType: state.Auth.userType
})
export default connect(mapStateToProps)(App);
