import {deleteUser} from '../utils/UserUtls'
const initilastate ={
    users:[],
    totalPages:"",
    currentPage:"",
    totalUsers:"",
    }

    const User =(state = initilastate,Action)=>{
        switch (Action.type) {
            case "GETUSERS":
                console.log(Action.payload)
                

                return{
                    ...state,
                    users:[...state.users,...Action.payload.users],
                    totalPages:Action.payload.totalPages,
                    currentPage:Action.payload.currentPage,
                    totalUsers:Action.payload.totalUsers,
                    }
            break;
            case "DELETEUSER":
                return{
                    ...state,
                    users:deleteUser(state.users,Action.payload.userID),
                    totalUsers:Action.payload.totalUsers,
                    totalPages:Action.payload.totalPages,
                    }
            break;
            case "EMPTYUSERS":
                return{
                    ...state,
                    users: [],
                    totalPages:"",
                    currentPage:"",
                    totalUsers:"",
                    }
            break;
            default:
                return state
                break;
        }
    
    }
    
    export default User
    
    

