import React, { useState, useEffect } from "react";
import $ from "jquery"
const useKeyPress = function(targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });

  return keyPressed;
};

// const props.items = [
//   { id: 1, name: "Josh Weir" },
//   { id: 2, name: "Sarah Weir" },
//   { id: 3, name: "Alicia Weir" },
//   { id: 4, name: "Doo Weir" },
//   { id: 5, name: "Grooft Weir" }
// ];

const ListItem = ({ item, active, setSelected, setHovered,ItemName,index,form_Name }) => (
  <div
    className={`item ${active ? "active"+" " : ""} form_search_${form_Name}_search-list_item`}
    onClick={() => setSelected(item)}
    onMouseEnter={() => setHovered(item)}
    onMouseLeave={() => setHovered(undefined)}
  >
    {ItemName}
  </div>
);

const SearchList = (props) => {
  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");
  const [cursor, setCursor] = useState(0);
  const [hovered, setHovered] = useState(undefined);

  // useEffect(() => {
  //   if ( props.items && downPress) {
  //     setCursor(prevState =>
  //       prevState < props.items.length - 1 ? prevState + 1 : prevState
  //     );
  //     document.getElementsByClassName("active")[0].scrollIntoView();
  //     // $('.form_search_item_search-list').scrollTop($('.active').offset().top+"px");

  //   }
    
  // }, [downPress]);
  // $(function() {
   
  // document.getElementById(`${props.Input_ID}`).onblur = function() {
  //   document.getElementsByClassName(`form_search_${props.form_Name}_search-list`)[0].classList.remove('show');
      // document.getElementsByClassName(`form_search_${props.form_Name}_search-list`)[0].classList.add('hide');
  // };
  
  // document.getElementById(`${props.Input_ID}`).onfocus = function() {
  //     // remove the "error" indication, because the user wants to re-enter something

  //     document.getElementsByClassName(`form_search_${props.form_Name}_search-list`)[0].classList.remove('hide');
  //   }
  //  $(`.form_search_${props.form_Name}`).blur() = function() {
  //     // remove the "error" indication, because the user wants to re-enter something
  //     document.getElementsByClassName(`form_search_${props.form_Name}_search-list`)[0].classList.add('show');
  //     document.getElementsByClassName(`form_search_${props.form_Name}_search-list`)[0].classList.remove('hide');
  //   }
  
    
  // })
  
   
  
  
  // $(function() {
  
  // $(`#${props.Input_ID}`).blur(function () {
  //   console.log("blur")
  //   $(`.form_search_${props.form_Name}_search-list`).hide()

  // })
  // $(`#${props.Input_ID}`).focus(function (e) {
  //   console.log("focus")
    // $(`.form_search_${props.form_Name}_search-list`).show()
  // })
  // })
  useEffect(() => {
    if ( props.items.length > 0 && downPress) {
      setCursor(prevState =>
        prevState < props.items.length - 1 ? prevState + 1 : prevState
      );
    // if (props.items.length > 1) {
      $(function() { document.getElementsByClassName(`form_search_${props.form_Name}_search-list`)[0].getElementsByClassName('active')[0].scrollIntoView();})
    // }
  
      // $('.form_search_item_search-list').scrollTop($('.active').offset().top+"px");
      // console.log(document.getElementsByClassName(`form_search_item_search-list`)[0].getElementsByClassName('active'))

    }
    if (props.items.length && upPress) {
      setCursor(prevState => (prevState > 0 ? prevState - 1 : prevState));
      // document.getElementsByClassName(`active`)[0].scrollIntoView();
      $(function() { document.getElementsByClassName(`form_search_${props.form_Name}_search-list`)[0].getElementsByClassName('active')[0].scrollIntoView();
      // $(`.form_search_${props.form_Name}_search-list`)[0].focus()
    })
    
    }
    
  }, [upPress,downPress]);
  useEffect(() => {
    if (props.items && props.items.length > 0  && enterPress) {
      props.setSelected(props.items[cursor]);
    }
  }, [cursor, enterPress]);
  useEffect(() => {
    if (props.items && hovered) {
      setCursor(props.items.indexOf(hovered));
    }
  }, [hovered]);
  useEffect(() => {
    setHovered(props.items[0])
  }, [props.items]);

  return (
<>
      {props.children}
      <ul className={`form_search_${props.form_Name}_search-list`} >
      {props.items?props.items.map((item, i) => (
        <ListItem
        index={i}
        form_Name={props.form_Name}
          key={item.id}
          active={i === cursor}
          item={item}
          ItemName={props.ItemName === "Item_Name"?`${item[props.ItemName]} | ${item[props.ItemCode]} | ${item[props.SystemID]}`:item[props.ItemName]}
          setSelected={props.setSelected}
          setHovered={setHovered}
        ></ListItem>
      )):null}
      </ul>
    </>
  );
};

export default SearchList