import React, { useState } from 'react';
import {NavLink} from 'react-router-dom'
import {connect,useDispatch} from 'react-redux'
import {LogOutHandler} from '../redux/actions/AuthAction'
import clsx from 'clsx';
import {Badge, Grid} from '@material-ui/core'
import Controls from './controls/Controls'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ConfirmDialog from "../components/ConfirmDialog";
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import NoteAdd from '@material-ui/icons/NoteAdd';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EmojiObjectsSharp from '@material-ui/icons/EmojiObjectsSharp';
import MailIcon from '@material-ui/icons/Mail';
import { Drafts, StarBorderSharp, SupervisedUserCircle,PeopleAlt,ListAlt,Receipt,LocationCity,Assessment } from '@material-ui/icons';

const drawerWidth = 190;

const useStyles = makeStyles((theme) => ({
  rightMenu:{
    display:'flex',
    justifyContent:'flex-end',
    "& > *":{
      marginLeft:".5rem"
    },
  },
  rightMenuGrid:{
    display:"flex",
    justifyContent:"flex-end",
  },
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('xs')]: {
      width: `calc(100% - 100px)`,
      zIndex:100
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    }
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    height:"100%",
    [theme.breakpoints.down('xs')]: {
      position:'absolute',
    },
    flexShrink: 0,
    whiteSpace: 'nowrap',
    "& .MuiTypography-root ":{
      fontWeight:"bold",
      fontSize:"1.6rem",
      [theme.breakpoints.down('xs')]: {
        fontSize:"1.2rem"
      }
    },
    "& .MuiSvgIcon-root":{
      fontSize:"2.6rem",
      [theme.breakpoints.down('xs')]: {
        fontSize:"2rem",
      }
    },
  },
  drawerOpen: {
    width: drawerWidth,
    [theme.breakpoints.down('xs')]: {
      position:'absolute',
      width: 150
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(6) + 1,
    },
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(0) + 1,
    },
  },
  ListItem:{
    [theme.breakpoints.between("sm","xl")]:{
      padding:".8rem .8rem !important"
    }
  },
  ListItemIcon:{
    minWidth:"3.5rem",
    [theme.breakpoints.down('md')]: {
      minWidth:"4.5rem",
    },
    [theme.breakpoints.down('sm')]: {
      minWidth:"4.2rem",
    },
    
   
  }
  ,
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    [theme.breakpoints.down(600)]:{
      minHeight:"60px "
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1.4,3),
    width:"100%",
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0),
    },
    "& input[type=Date]":{
      [theme.breakpoints.down('xs')]:{
        height:"0rem !important",
        padding:"1rem 2rem 3rem 2rem !important"
      }
    }
  },
}));

const MiniDrawer = (props)=> {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch()
  const [nestedopen, setNestedopen] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })

  const handleClick = () => {
    setNestedopen(!nestedopen);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const LogOutHandle=()=>{
    
    dispatch(LogOutHandler())
  }

  const onLogout = () => {
    setConfirmDialog({
        ...confirmDialog,
        isOpen: false
    })
    LogOutHandle()
}
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Grid  container  className={classes.rightMenuGrid} >
            <Grid item xs={8} sm={4}  md={3}  lg={2}>
         <div className={classes.rightMenu}>
           <Button><Typography variant='h6' >{props.userName}</Typography>  
           </Button>
           <Controls.ActionButton
              colorPrimary="colorSecondary"
              onClick={() => {
                setConfirmDialog({
                isOpen: true,
                title: 'Are you sure to Logout ?',
                subTitle: "",
                onConfirm: () => { onLogout() }
                })
                }}
              fontSize="medium">
                <Typography color='textSecondary'>LOGOUT</Typography>
            </Controls.ActionButton>
          
         </div>
         </Grid>
         </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <img style={{width:"3.9rem",}} src={`${process.env.PUBLIC_URL}/simple-solutions-logo.png`} ></img>
          <Typography variant="subtitle2" className="Simple_Solutions_Logo">SIMPLE <Typography variant="subtitle2" className="Simple_Solutions_Logo_child" >SOLUTIONS</Typography></Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
       {props.userRole === 'Administrator' ?(<NavLink   to='users'>
            <ListItem key='Users' className={classes.ListItem} onClick={handleDrawerClose} >
              <ListItemIcon className={classes.ListItemIcon}><SupervisedUserCircle/></ListItemIcon>
              <ListItemText primary='Users' />
            </ListItem>
           </NavLink>):(null)}
           <NavLink   to='customers'>
            <ListItem key='Customers' className={classes.ListItem} onClick={handleDrawerClose} >
              <ListItemIcon className={classes.ListItemIcon}><PeopleAlt/></ListItemIcon>
              <ListItemText primary='Customers' />
            </ListItem>
           </NavLink>
           <NavLink   to='items'>
            <ListItem key='Items' className={classes.ListItem} onClick={handleDrawerClose} >
              <ListItemIcon className={classes.ListItemIcon}><ListAlt/></ListItemIcon>
              <ListItemText primary='Items' />
            </ListItem>
           </NavLink>
           <NavLink  to='units' >
          <ListItem key='units' className={classes.ListItem}  onClick={handleDrawerClose} >
            <ListItemIcon className={classes.ListItemIcon}><Assessment/></ListItemIcon>
            <ListItemText primary="Units" />
          </ListItem>
          </NavLink>
           <NavLink  to='areas' >
          <ListItem key='Areas' className={classes.ListItem}  onClick={handleDrawerClose} >
            <ListItemIcon className={classes.ListItemIcon}><LocationCity /></ListItemIcon>
            <ListItemText primary="Areas" />
          </ListItem>
          </NavLink>
           <NavLink  to='vieworders' >
          <ListItem key='vieworders' className={classes.ListItem}  onClick={handleDrawerClose} >
            <ListItemIcon className={classes.ListItemIcon}><Receipt /></ListItemIcon>
            <ListItemText primary="View Orders" />
          </ListItem>
          </NavLink>
           {/* <ListItem className={classes.ListItem} button onClick={handleClick}>
        <ListItemIcon className={classes.ListItemIcon}>
          <EmojiObjectsSharp />
        </ListItemIcon>
        <ListItemText primary="purchases" />
        {nestedopen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={nestedopen} timeout="auto" unmountOnExit> */}
        {/* <NavLink  to='addorder'   onClick={handleDrawerClose}>
          <ListItem className={classes.ListItem} button >
            <ListItemIcon className={classes.ListItemIcon}>
              <NoteAdd />
            </ListItemIcon>
            <ListItemText primary="Add Order" />
          </ListItem>
          </NavLink> */}
          
        
      {/* </Collapse> */}
        </List>
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
       <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
    </div>
  );
}
const mapStateToProps=state=>({
  userName: state.Auth.userName,
  userRole:state.Auth.userType
})

export default connect(mapStateToProps)(MiniDrawer)