import {GETITEMS,UPDATEITEM,DELETEITEM,EMPTYITEMS} from './types'
import axios from 'axios'

export const GetItems =(data,setLoading)=>dispatch=>{
    const headers={
        "x-access-token": sessionStorage.getItem("accountingauthkey")
    }
    axios.post('/api/getitems',data,{headers:headers}).then(res=>{console.log(res) 
      setLoading(false)
    dispatch({type:GETITEMS,payload:res.data})})
}

export const EmptyItems =()=>dispatch=>{
  dispatch({type:EMPTYITEMS})
}
export const UpdateItem=(item)=>dispatch=>{
  const headers={
      "x-access-token": sessionStorage.getItem("accountingauthkey")
  }
  axios('/api/updateitem',item,headers).then(res=>dispatch({type:UPDATEITEM,payload:res.message}))
}

export const DeleteItem =(itemID,totalPages,totalItems)=>(
  {type:DELETEITEM,
  payload:{itemID,totalPages,totalItems}}
)