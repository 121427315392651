import React, { useState,useRef } from 'react'
import ItemForm from "./ItemForm";
import PageHeader from "../../components/PageHeader";
import axios from 'axios'
import ListAlt from '@material-ui/icons/ListAlt';
import { Paper, makeStyles, TableBody, TableRow, TableCell, Toolbar, InputAdornment, Box,Grid, IconButton } from '@material-ui/core';
import useTable from "../../components/useTable";
import Controls from "../../components/controls/Controls";
import {connect} from 'react-redux'
import { Search } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import Print from '@material-ui/icons/Print';
import FileCopy from '@material-ui/icons/FileCopy';
import Popup from "../../components/Popup";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close'; 
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import {GetItems,EmptyItems,DeleteItem} from '../../redux/actions/ItemsAction'
import jsPDF from 'jspdf';
import misc from '../../fnc/misc'
import 'jspdf-autotable';
const useStyles = makeStyles(theme => ({

    pageContent: {
        padding: "2rem 2rem 0 2rem",
        height: `calc(${window.innerHeight}px - 180px)`,
        [theme.breakpoints.up('md')]:{
            height: `calc(${window.innerHeight}px - 200px)`,
        },
        overflow: "hiddin",
        "& .MuiGrid-item > *":{
       
            maxWidth: `calc(100% - 1rem) !important`,
            width: `calc(100% - 1rem) !important`,
            marginRight: `1rem !important`
       },
       "& .table-container":{
        height: `calc(${window.innerHeight}px - 250px)`,
        [theme.breakpoints.down("xs")]:{
            height: `calc(${window.innerHeight}px - 200px)`
        },
        overflow:"auto",
        "& [name=actions]":{
            "& svg":{
                visibility:"hidden"
            }
        },
        "& #scroll_Btn":{
            margin:"auto",display:"none",
            [theme.breakpoints.between("xs","sm")]:{
                display:"block"
            }
        }
    }
    },
    Toolbar:{
    },
    
    ToolbarItems:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        [theme.breakpoints.between("xs","sm")]:{
            
            paddingLeft:"1rem"
        },
    },
    
       
    buttonContainerGrid:{
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-end",
        [theme.breakpoints.between("xs","sm")]:{
            marginRight:"2rem",
        },
        "& button":{
           
            width:"32.333%",
            [theme.breakpoints.between("md","xl")]:{
                marginRight:".3rem",
            },
        },

 
  },
    newButtonMobile:{
        padding:0,
        margin:0,
        [theme.breakpoints.down('md')]: {
            display:"flex"
          },
          [theme.breakpoints.up('md')]: {
            display:"none"
          },
    },
    newButton:{
        height: "4rem",
        margin:0,
        [theme.breakpoints.down('md')]: {
            display:"none"
          },
          [theme.breakpoints.up('md')]: {
            display:"flex"
          },
    }
}))

const defaultProps = {
    bgcolor: 'background.paper',
    borderColor: 'inherit',
    m: 1,
    padding:0,
    border: 1,
    style: { width: '4rem', height: '4rem' },
    display:"flex",
    margin:"0rem",
    alignItems:"center",
    justifyContent:"center",
    
  };
const headCells = [
    { id: 'Item_Name', label: 'Item Name' },
    { id: 'System_ID', label: 'System ID', name:"id" },
    { id: 'Item_Code', label: 'Item Code' },
    { id: 'Item_Price', label: 'Price', name:"amount" },
    { id: 'actions', label: 'Actions', name:'actions' }
]

const Items = (props)=> {

    const classes = useStyles();
    const [recordForEdit, setRecordForEdit] = useState(null)
    const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
    const [openPopup, setOpenPopup] = useState(false)
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const componentRef = useRef();
    const [loading, setLoading] = useState(false)
    const [searchItemName,setSearchItemName] =useState(null)
    
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
        rowsPerPage,
        page,
        setPage,
        handleScroll
    } = useTable(props.Items_List, headCells, filterFn,componentRef,'medium',props.totalPages,props.currentPage,props.totalItems,props.getAllItems,props.EmptyItems,true,setLoading);
    const handleSearch = e => {
        let target = e.target;
        setFilterFn({
            fn: items => {
                if (target.value == "")
                    return items;
                else
                    return items.filter(x => `${x.Item_Name}${x.Item_Code}`.toLowerCase().includes(target.value)||`${x.Item_Name}${x.Item_Code}`.toUpperCase().includes(target.value)||  misc.capitalize(`${x.Item_Name}${x.Item_Code}`).includes(target.value))
            }
        })
    }

   
// generte pdf code
const generatePdf = ()=> {
var doc = new jsPDF();
doc.autoTable({
    html: '#table',
    theme:'grid',
    textColor:22
});
doc.save('table.pdf');
};


// generate pdf code
    const insertItem = (item)=>{
        console.log('insert run')
        const headers={
          "x-access-token": sessionStorage.getItem("accountingauthkey")
      }
     axios.post('/api/additem',item,{headers:headers}).then(res=>{
         setNotify({
        isOpen: true,
        message: res.data.message,
        type: res.data.iserror ? 'error':'success'
    })
    if(res.data.iserror === false){
    props.EmptyItems()
    setPage(0)
    props.getAllItems({page:0,limit:rowsPerPage},setLoading)
    setLoading(false)
    }
      }
      )
    }
    const updateItem = (item)=>{
        console.log('update run')
        console.log(item)
        const headers={
          "x-access-token": sessionStorage.getItem("accountingauthkey")
      }
     axios.post('/api/updateitem',item,{headers:headers}).then(res=>{
         setNotify({
        isOpen: true,
        message: res.data.message,
        type: res.data.iserror ? 'error':'success'
    })
    if(res.data.iserror === false){
        props.EmptyItems()
        setPage(0)
        props.getAllItems({page:0,limit:rowsPerPage},setLoading)
        setLoading(false)
        }
     })
    }
    const addOrEdit = (Item, resetForm) => {
  
        if (!Item.Item_ID){
          
           insertItem(Item)
        }
        else if( Item.Item_ID  ){
            updateItem(Item)
        }
        resetForm()
        setRecordForEdit(null)
        setOpenPopup(false)
    }


    const openInPopup = item => {
        setRecordForEdit(item)
        setOpenPopup(true)
    }
   const deleteItem=(itemName,itemID)=>{
    const headers={
      "x-access-token": sessionStorage.getItem("accountingauthkey")
  }
  console.log('delete run',itemName)
 axios.post('/api/deleteitem',{Item_ID:itemID},{headers:headers}).then(res=>{
     setNotify({
    isOpen: true,
    message: res.data.message,
    type: res.data.iserror ? 'error':'success'
})
if(res.data.iserror === false){
    props.EmptyItems()
    setPage(0)
    props.getAllItems({page:0,limit:rowsPerPage},setLoading)
    setLoading(false)
    }
 })
    }
    const onDeleteItem = (itemName,itemID) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        deleteItem(itemName,itemID);
    }
    const changeSearchState = (e) => {
        const searchItemNameValue = e.target.value
      if (searchItemNameValue !== '') {
        setSearchItemName(searchItemNameValue)
      } else {
           setSearchItemName(null)
      }
        
    }


    
    return (
        <>
            <PageHeader 
                title="Items"
                icon={<ListAlt color="primary" fontSize="large" />}
            >
            <Grid container  className={classes.ToolbarItems} lg={8} md={9} sm={12} xs={12} >
                <Grid container lg={5} md={5} sm={6} xs={6}   >
                    <Controls.Input
                        label="Search"
                        width="100%"
                        InputProps={{
                            startAdornment: (<InputAdornment position="start">
                                <Search />
                            </InputAdornment>)
                        }}
                        onChange={handleSearch}
                    />
                    </Grid>
                    <Grid className={classes.buttonContainerGrid}   container lg={7} md={7} sm={4} xs={5} >
                    
                    <IconButton
                        variant="outlined"
                        className={classes.newButtonMobile}
                        onClick={() => { setOpenPopup(true); setRecordForEdit(null); }}
                    ><Box borderRadius="50%" {...defaultProps}><AddIcon /></Box></IconButton>
                    <Controls.Button
                        text="Add New"
                        width="100%"
                        height="4rem"
                        startIcon={<AddIcon />}
                        className={classes.newButton}
                        onClick={() => { setOpenPopup(true); setRecordForEdit(null); }}
                    />
                
                    <IconButton
                        className={classes.newButtonMobile}
                        onClick={generatePdf}
                    ><Box borderRadius="50%" {...defaultProps}><Print /></Box></IconButton>
                    <Controls.Button
                        text="Print"
                        width="100%"
                        height="4rem"
                        startIcon={<Print/>}
                        className={classes.newButton}
                        onClick={generatePdf}
                    />

                    <IconButton
                        
                        className={classes.newButtonMobile}
                        onClick={()=>misc.exportTableToExcel('table')}
                    ><Box borderRadius="50%" {...defaultProps}><FileCopy /></Box></IconButton>
                    <Controls.Button
                        text="Excel"
                        width="100%"
                        height="4rem"
                        startIcon={<FileCopy/>}
                        className={classes.newButton}
                        onClick={()=>misc.exportTableToExcel('table')}
                    />
                    
                  
                    </Grid>
                </Grid>
            </PageHeader>
            <Paper elevation={7} className={classes.pageContent}>
            <div className="table-container" onScroll={handleScroll}>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {props.Items_List !== null?(
                            recordsAfterPagingAndSorting().map(Items_List_ITEM =>
                                (<TableRow key={Items_List_ITEM.Item_ID} >
                                    <TableCell  data-heading='ITEM Name' >{Items_List_ITEM.Item_Name}</TableCell>
                                    <TableCell name="id" data-heading='SYSTEM ID' >{Items_List_ITEM.System_ID}</TableCell>
                                    <TableCell  data-heading='ITEM CODE' >{Items_List_ITEM.Item_Code}</TableCell>
                                    <TableCell  name="amount"  data-heading='PRICE' >{Items_List_ITEM.Item_Price}</TableCell>
                                    <TableCell  data-heading='Action' >
                                        <>
                                        <Controls.ActionButton
                                           colorPrimary="colorPrimary"
                                            onClick={() => { openInPopup(Items_List_ITEM) }}>
                                            <EditOutlinedIcon fontSize="small" />
                                        </Controls.ActionButton>
                                        <Controls.ActionButton
                                            colorPrimary="colorSecondary"
                                            onClick={() => {
                                                setConfirmDialog({
                                                isOpen: true,
                                                title: 'Are you sure to delete ?',
                                                subTitle: "You can't undo this operation",
                                                onConfirm: () => { onDeleteItem(null,Items_List_ITEM.Item_ID) }
                                                })
                                                }}>
                                            
                                            <CloseIcon fontSize="small" />
                                        </Controls.ActionButton></>
                                    </TableCell>
                                </TableRow>)
                            )):(<Controls.Loader/>)
                        }
                    </TableBody>
                    
                {page < props.totalPages?<Controls.Button id="scroll_Btn"  width="100%" height="4rem" text="Load" onClick={()=>page < props.totalPages ? setPage(page+1):null}></Controls.Button>:null}
                </TblContainer>
                {loading?<Controls.Loader/>:null}
                </div>
<table id="table" style={{width:" 100%",borderCollapse: "collapse",border: "1px solid black",display:"none"}} >
  <tr>
    {headCells.map((Items_List_ITEM,index)=> Items_List_ITEM.label !== "Actions"?<th key={index} style={{border: "1px solid black"}}>{Items_List_ITEM.label}</th>:null)}
  </tr>
  {props.Items_List?recordsAfterPagingAndSorting().map((Items_List_ITEM,index)=>(
  <tr key={index}>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='ITEM Name' >{Items_List_ITEM.Item_Name}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='SYSTEM ID' >{Items_List_ITEM.System_ID}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='ITEM CODE' >{Items_List_ITEM.Item_Code}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='PRICE' >{Items_List_ITEM.Item_Price}</td>
</tr>)):null}
</table>
            
            </Paper>
            <Popup
                title="Items Form"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <ItemForm
                    recordForEdit={recordForEdit}
                    addOrEdit={addOrEdit} />
            </Popup>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        
        </>
    )
}
const mapStateToProps=state=>({
    Items_List: state.ItemsList.Items_List,
    totalPages:state.ItemsList.totalPages,
    currentPage:state.ItemsList.currentPage,
    totalItems:state.ItemsList.totalItems
  })
  const mapDispatchToProps = dispatch=>({
    getAllItems : (data,setLoading)=>{dispatch(GetItems(data,setLoading))},
    EmptyItems:()=>{dispatch(EmptyItems())},
    DeleteItem:(itemID,totalPages,totalItems)=>{dispatch(DeleteItem(itemID,totalPages,totalItems))}
  })
  export default connect(mapStateToProps,mapDispatchToProps)(Items)

