import React, { useState,useRef } from 'react'
import AreaForm from "./AreasForm";
import PageHeader from "../../components/PageHeader";
import axios from 'axios'
import ListAlt from '@material-ui/icons/ListAlt';
import { Paper, makeStyles, TableBody, TableRow, TableCell, Toolbar, InputAdornment, Box,Grid, IconButton, } from '@material-ui/core';
import useTable from "../../components/useTable";
import Controls from "../../components/controls/Controls";
import {connect} from 'react-redux'
import { Search,LocationCity } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import Print from '@material-ui/icons/Print';
import FileCopy from '@material-ui/icons/FileCopy';
import Popup from "../../components/Popup";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close'; 
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import {GetAreas,EmptyAreas,DeleteArea} from '../../redux/actions/AreasAction'
import jsPDF from 'jspdf';
import misc from '../../fnc/misc'
import 'jspdf-autotable';
const useStyles = makeStyles(theme => ({

    pageContent: {
        padding: "2rem 2rem 0 2rem",
        height: `calc(${window.innerHeight}px - 150px)`,
        [theme.breakpoints.up('md')]:{
            height: `calc(${window.innerHeight}px - 200px)`,
        },
        overflow: "hiddin",
        "& .MuiGrid-item > *":{
       
            maxWidth: `calc(100% - 1rem) !important`,
            width: `calc(100% - 1rem) !important`,
            marginRight: `1rem !important`
       },
       "& .table-container":{
        height: `calc(${window.innerHeight}px - 250px)`,
        overflow:"auto",
        "& [name=actions]":{
            "& svg":{
                visibility:"hidden"
            }
        },
        "& #scroll_Btn":{
            margin:"auto",display:"none",
            [theme.breakpoints.between("xs","sm")]:{
                display:"block"
            }
        }
    }
    },
    Toolbar:{
    },
    
    ToolbarItems:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        [theme.breakpoints.between("xs","sm")]:{
            
            paddingLeft:"1rem"
        },
    },
    
       
    buttonContainerGrid:{
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-end",
        [theme.breakpoints.between("xs","sm")]:{
            marginRight:"2rem",
        },
        "& button":{
           
            width:"32.333%",
            [theme.breakpoints.between("md","xl")]:{
                marginRight:".3rem",
            },
        },

 
  },
    newButtonMobile:{
        padding:0,
        margin:0,
        [theme.breakpoints.down('md')]: {
            display:"flex"
          },
          [theme.breakpoints.up('md')]: {
            display:"none"
          },
    },
    newButton:{
        height: "4rem",
        margin:0,
        [theme.breakpoints.down('md')]: {
            display:"none"
          },
          [theme.breakpoints.up('md')]: {
            display:"flex"
          },
    }
}))

const defaultProps = {
    bgcolor: 'background.paper',
    borderColor: 'inherit',
    m: 1,
    padding:0,
    border: 1,
    style: { width: '4rem', height: '4rem' },
    display:"flex",
    margin:"0rem",
    alignItems:"center",
    justifyContent:"center",
    
  };
const headCells = [
    { id: 'Area_Name', label: 'Area Name' },
    { id: 'Area_ID', label: 'Area ID', name:"id" },
    { id: 'Control_Account', label: 'Account Controll',name:"id" },
    { id: 'User_Name', label: 'User Name', },
    { id: 'actions', label: 'Actions', name:'actions' }
]

const Areas = (props)=> {

    const classes = useStyles();
    const [recordForEdit, setRecordForEdit] = useState(null)
    const [filterFn, setFilterFn] = useState({ fn: areas => { return areas; } })
    const [openPopup, setOpenPopup] = useState(false)
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const componentRef = useRef();
    const [loading, setLoading] = useState(false)
    const [searchAreaName,setSearchAreaName] =useState(null)
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
        rowsPerPage,
        page,
        setPage,
        handleScroll
    } = useTable(props.Areas_List, headCells, filterFn,componentRef,'medium',props.totalPages,props.currentPage,props.totalAreas,props.getAllAreas,props.EmptyAreas,true,setLoading);
    const handleSearch = e => {
        let target = e.target;
        setFilterFn({
            fn: areas => {
                if (target.value == "")
                    return areas;
                else
                    return areas.filter(x => `${x.Area_Name}${x.Control_Account}`.toLowerCase().includes(target.value)||`${x.Area_Name}${x.Control_Account}`.toUpperCase().includes(target.value)||  misc.capitalize(`${x.Area_Name}${x.Control_Account}`).includes(target.value))
            }
        })
    }

   
// generte pdf code
const generatePdf = ()=> {
var doc = new jsPDF();
doc.autoTable({
    html: '#table',
    theme:'grid',
    textColor:22
});
doc.save('table.pdf');
};


// generate pdf code
    const insertArea = (area)=>{
        console.log('insert run')
        const headers={
          "x-access-token":sessionStorage.getItem("accountingauthkey")
      }
     axios.post('/api/addarea',area,{headers:headers}).then(res=>{
         setNotify({
        isOpen: true,
        message: res.data.message,
        type: res.data.iserror ? 'error':'success'
    })
    if(res.data.iserror === false){
    props.EmptyAreas()
    setPage(0)
    props.getAllAreas({page:0,limit:rowsPerPage})
    }
      }
      )
    }
    const updateArea = (area)=>{
        console.log('update run')
        console.log(area)
        const headers={
          "x-access-token":sessionStorage.getItem("accountingauthkey")
      }
     axios.post('/api/updatearea',area,{headers:headers}).then(res=>{
         setNotify({
        isOpen: true,
        message: res.data.message,
        type: res.data.iserror ? 'error':'success'
    })
    if(res.data.iserror === false){
        props.EmptyAreas()
        setPage(0)
        props.getAllAreas({page:0,limit:rowsPerPage},setLoading)
        }
     })
    }
    const addOrEdit = (Area, resetForm) => {
  
        if (!Area.Area_ID){
          
           insertArea(Area)
        }
        else if( Area.Area_ID  ){
            updateArea(Area)
        }
        resetForm()
        setRecordForEdit(null)
        setOpenPopup(false)
    }


    const openInPopup = area => {
        setRecordForEdit(area)
        setOpenPopup(true)
    }
   const deleteArea=(areaName,areaID)=>{
    const headers={
      "x-access-token":sessionStorage.getItem("accountingauthkey")
  }
  console.log('delete run',areaID)
 axios.post('/api/deletearea',{Area_ID:areaID},{headers:headers}).then(res=>{
     setNotify({
    isOpen: true,
    message: res.data.message,
    type: res.data.iserror ? 'error':'success'
})
if(res.data.iserror === false){
    props.EmptyAreas()
    setPage(0)
    props.getAllAreas({page:0,limit:rowsPerPage},setLoading)
    }
 })
    }
    const changeSearchState = (e) => {
        const searchAreaNameValue = e.target.value
      if (searchAreaNameValue !== '') {
        setSearchAreaName(searchAreaNameValue)
      } else {
           setSearchAreaName(null)
      }
        
    }

    const onDeleteArea = (AreaName,Area_ID) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
       deleteArea(null,Area_ID)
    }
    return (
        <>
            <PageHeader 
                title="Areas"
                icon={<LocationCity color="primary" fontSize="large" />}
            >
            <Grid container  className={classes.ToolbarItems} lg={8} md={9} sm={12} xs={12} >
                <Grid container lg={5} md={5} sm={6} xs={6}   >
                    <Controls.Input
                        label="Search"
                        width="100%"
                        InputProps={{
                            startAdornment: (<InputAdornment position="start">
                                <Search />
                            </InputAdornment>)
                        }}
                        onChange={handleSearch}
                    />
                    </Grid>
                    <Grid className={classes.buttonContainerGrid}   container lg={7} md={7} sm={4} xs={5} >
                    
                    <IconButton
                        variant="outlined"
                        className={classes.newButtonMobile}
                        onClick={() => { setOpenPopup(true); setRecordForEdit(null); }}
                    ><Box borderRadius="50%" {...defaultProps}><AddIcon /></Box></IconButton>
                    <Controls.Button
                        text="Add New"
                        width="100%"
                        height="4rem"
                        startIcon={<AddIcon />}
                        className={classes.newButton}
                        onClick={() => { setOpenPopup(true); setRecordForEdit(null); }}
                    />
                
                    <IconButton
                        className={classes.newButtonMobile}
                        onClick={generatePdf}
                    ><Box borderRadius="50%" {...defaultProps}><Print /></Box></IconButton>
                    <Controls.Button
                        text="Print"
                        width="100%"
                        height="4rem"
                        startIcon={<Print/>}
                        className={classes.newButton}
                        onClick={generatePdf}
                    />

                    <IconButton
                        
                        className={classes.newButtonMobile}
                        onClick={()=>misc.exportTableToExcel('table')}
                    ><Box borderRadius="50%" {...defaultProps}><FileCopy /></Box></IconButton>
                    <Controls.Button
                        text="Excel"
                        width="100%"
                        height="4rem"
                        startIcon={<FileCopy/>}
                        className={classes.newButton}
                        onClick={()=>misc.exportTableToExcel('table')}
                    />
                    
                  
                    </Grid>
                </Grid>
            </PageHeader>
            <Paper elevation={7} className={classes.pageContent}>
            <div className="table-container" onScroll={handleScroll}>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {props.Areas_List !== null?(
                            recordsAfterPagingAndSorting().map(Areas_List_Area =>
                                (<TableRow key={Areas_List_Area.Area_ID} >
                                    <TableCell  data-heading='Area Name' >{Areas_List_Area.Area_Name}</TableCell>
                                    <TableCell name="id" data-heading='Area ID' >{Areas_List_Area.Area_ID}</TableCell>
                                    <TableCell name="id" data-heading='Account Controll' >{Areas_List_Area.Control_Account}</TableCell>
                                    <TableCell   data-heading='User Name' >{Areas_List_Area.User_Name}</TableCell>
                                    <TableCell  data-heading='Action' >
                                        <>
                                        <Controls.ActionButton
                                            colorPrimary="colorPrimary"
                                            onClick={() => { openInPopup(Areas_List_Area) }}>
                                            <EditOutlinedIcon fontSize="small" />
                                        </Controls.ActionButton>
                                        <Controls.ActionButton
                                        colorPrimary="colorSecondary"
                                            onClick={() => {
                                                setConfirmDialog({
                                                isOpen: true,
                                                title: 'Are you sure to delete ?',
                                                subTitle: "You can't undo this operation",
                                                onConfirm: () => { onDeleteArea(null,Areas_List_Area.Area_ID) }
                                                })
                                                }}>
                                            <CloseIcon fontSize="small" />
                                        </Controls.ActionButton></>
                                    </TableCell>
                                </TableRow>)
                            )):(<Controls.Loader/>)
                        }
                        {page < props.totalPages ?<Controls.Button id="scroll_Btn"  width="100%" height="4rem" text="Load" onClick={()=>page < props.totalPages ? setPage(page+1):null}></Controls.Button>:null}
                    </TableBody>
                    
                </TblContainer>
                {loading?<Controls.Loader/>:null}
                </div>
<table id="table" style={{width:" 100%",borderCollapse: "collapse",border: "1px solid black",display:"none"}} >
  <tr>
    {headCells.map((Areas_List_Area,index)=> Areas_List_Area.label !== "Actions"?<th key={index} style={{border: "1px solid black"}}>{Areas_List_Area.label}</th>:null)}
  </tr>
  {props.Areas_List?recordsAfterPagingAndSorting().map((Areas_List_Area,index)=>(
  <tr key={index}>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='ITEM Name' >{Areas_List_Area.Area_Name}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='SYSTEM ID' >{Areas_List_Area.Area_ID}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='ITEM CODE' >{Areas_List_Area.Control_Account}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='PRICE' >{Areas_List_Area.User_Name}</td>
</tr>)):null}
</table>
            
            </Paper>
            <Popup
                title="Areas Form"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <AreaForm
                    recordForEdit={recordForEdit}
                    addOrEdit={addOrEdit} />
            </Popup>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        
        </>
    )
}
const mapStateToProps=state=>({ 
    Areas_List: state.AreasList.Areas_List,
    totalPages:state.AreasList.totalPages,
    currentPage:state.AreasList.currentPage,
    totalAreas:state.AreasList.totalAreas
  })
  const mapDispatchToProps = dispatch=>({
    getAllAreas : (data,setLoading)=>{dispatch(GetAreas(data,setLoading))},
    EmptyAreas:()=>{dispatch(EmptyAreas())},
    DeleteArea:(areaID,totalPages,totalAreas)=>{dispatch(DeleteArea(areaID,totalPages,totalAreas))}
  })
  export default connect(mapStateToProps,mapDispatchToProps)(Areas)

