import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import {useLocation,useHistory} from 'react-router-dom'
import useTable from "../../components/useTable";
import {DeleteForever,Edit, NoteAdd,Search} from '@material-ui/icons';
import { Paper, Toolbar,makeStyles, Grid,TableBody, TableRow,TableCell,Typography,InputAdornment} from '@material-ui/core';
import Controls from "../../components/controls/Controls";
import Notification from '../../components/Notification'
import {connect} from 'react-redux'
import {addItem,deleteItem,emptyOrderItemsList, setItems } from "../../redux/actions/orderItemsAction"
import {GetAreas,EmptyAreas} from '../../redux/actions/AreasAction'
import 'jspdf-autotable';
import $ from "jquery";
import misc from "../../fnc/misc";
import PageHeader from "../../components/PageHeader";
import { useTabIndex } from 'react-tabindex';
import ConfirmDialog from "../../components/ConfirmDialog";

let myheight = window.innerHeight
let myWidth = window.innerWidth
const useStyles = makeStyles(theme => ({
    pageContent: {
        padding: theme.spacing(3,3,3,3),
        height: `calc(${myheight}px - 90px)`,
        // [theme.breakpoints.down('md')]:{
        //     maxHeight: "53rem",
        // },
        [theme.breakpoints.down('xs')]:{
            height: `calc(${myheight}px - 60px)`,
        },
        overflowX: "auto",
       "& .MuiGrid-item > *":{
       
            maxWidth: `calc(100% - 1rem) !important`,
            width: `calc(100% - 1rem) !important`,
            marginRight: `1rem !important`
       },
       "& .table-container":{
        height: `calc(${myheight}px - 1rem - 410px)`,
        [theme.breakpoints.only('md')]:{
            height: `calc(${myheight}px - 1rem - 400px)`
        },
        [theme.breakpoints.only('xs')]:{
            height: `calc(${myheight}px - 1rem - 440px)`
        },
        overFlow:"auto",
        marginBottom:"1rem",
        paddingLeft:"0rem !important",
        "& thead":{
            [theme.breakpoints.down(900)]:{
            display:"table-header-group",
            backgroundColor:"#739FDF",
            width:"100%",
            
            "&  tr" :{
                display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    padding: "0rem .2rem 0rem .2rem",
                    backgroundColor:"#739FDF",
                "& [name=name]":{
                    width:"25% !important"
                },
                "& [name=code]":{
                    width:"5% !important"
                },
                "& [name=qty]":{
                    width:"5% !important"
                },
                "& [name=amount]":{
                    width:"5% !important"
                },
              "& th":{
                //   width:"1fr",
                color: "inherit",
                padding: "0",
                backgroundColor:"#739FDF",
                textAlign: "left",
                fontSize:"1rem",
                
              }
              }
            }
        },
        "& tbody":{
            [theme.breakpoints.down(900)]:{
                "& tr":{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    padding: "0rem .2rem 0rem .2rem",
                    "& [name=name]":{
                        width:"25% !important",
                    },
                    "& [name=code]":{
                        width:"5% !important"
                    },
                    "& [name=qty]":{
                        width:"5% !important"
                    },
                    "& [name=amount]":{
                        width:"5% !important"
                    },
                
                },
                "& td":{
                    // width:"1fr",
                    display: "inline",
                    position: "static",
                    padding: "0",
                    textAlign: "left",
                    borderBottom: 0,
                    marginTop : "0rem",
                    "&::before":{
                        display:"none !important"
                    }
                },
            }

        },
        
        "& .code":{
            [theme.breakpoints.down('xs')]:{
                display:"none"
            }
        },
        },
      
    },
    
    ToolbarItems:{
        display:"flex",
        alignItems:"center",
        
    },
    AddOrderHeader:{ 
        "& > * ":{
            marginBottom: "2rem"
        }
    },
    newButton:{
        height: "3.7rem",
        margin:0,
        padding:0,
    },
    TotalDiv:{
        display: "flex",
        justifyContent:"space-between",
        alignItems:"center",
        [theme.breakpoints.down(599)]:{
            display:"none"
        }
    },
    orderID:{
        paddingTop:".9rem",
        display:"flex",
        [theme.breakpoints.down('xs')]:{
            paddingTop:"0rem",
            alignItems:"center"
        }

    },
    mobileSaveBtn:{
        [theme.breakpoints.up(599)]:{
            display:"none"
        }
    },
    mobileTotalDiv:{
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-end",
        marginBottom:".5rem !important",
        "& > *":{
            width:"fit-contant",
            textAlign:"end"
        },
        [theme.breakpoints.up(599)]:{
            display:"none",
            
        }
    },
    mobileAddBtn:{
        [theme.breakpoints.down(599)]:{
            marginBottom:".5rem !important",
        }
    },
    ActionBtnDiv:{
        [theme.breakpoints.down(900)]:{
            // display:"flex !important",
            // flexDirection:"column"
            "& > *":{
                width:"2rem",
                height:"2rem",
                padding:".5rem"
            },
            "& svg":{
                fontSize:"1rem"
            }
        }
    }
  
    
}))


const AddOrder = (props)=> {
  
    $(document).ready(function () {
        $("input").attr("autocomplete","off");
            });
            let headCells
            myWidth >= 599 ? headCells = [
        { id: 'Item_Name', label: 'ITEM NAME',disableSorting:true,name:"name" },
        { id: 'Unit_Name', label: 'Unit',disableSorting:true,name:"unit" },
        { id: 'Item_Code', label: 'CODE',disableSorting:true,name:"code" },
        { id: 'Item_Qty', label: 'QTY',disableSorting:true,name:"qty" },
        { id: 'Item_Price', label: 'PRICE',disableSorting:true,name:"amount" },
        { id: 'Item_Amount', label: 'AMOUNT',disableSorting:true,name:"amount" },
        { id: 'actions', label: 'ACTIONS',disableSorting:true,name:"actions"}
    ]:headCells = [
        { id: 'Item_Name', label: 'NAME',disableSorting:true,name:"name" },
        { id: 'Unit_Name', label: 'Unit',disableSorting:true,name:"unit" },
        { id: 'Item_Qty', label: 'QTY',disableSorting:true,name:"qty" },
        { id: 'Item_Price', label: 'PRICE',disableSorting:true,name:"amount" },
        { id: 'Item_Amount', label: 'AMOUNT',disableSorting:true,name:"amount" },
        { id: 'actions', label: 'ACTIONS',disableSorting:true,name:"actions"}
    ]
    // Customers List Values
    const classes = useStyles();
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const [values,setValues] = useState({Order_ID:null,Customer_ID:"",Customer_Name:"",Order_Reference:"",Area_ID:"",Area_Name:"",Order_Amount:"00.00"})
    const location = useLocation() 
    const [Units,SetUnits]=useState([])  
    const history = useHistory()
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const [Order_Date,set_Order_Date]=useState(new Date())
    const tabIndex = useTabIndex();
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
        rowsPerPage,
        page,
        setPage
    } = useTable(props.orderItemsList, headCells, null,null,'medium',null,null,null,()=>{},null,false);
    
    const  manageOrderTotal=(total, Order_Items_List)=>{
        return total + Order_Items_List.Order_Item_Amount;
      }
// Order_Amount Change
useEffect(()=>{
    const newTotal = props.orderItemsList.Order_Items_List.reduce(manageOrderTotal,0);
    setValues({...values,Order_Amount:newTotal})
},[props.orderItemsList])
// general input change function
    const handleInputChange = e => {
                setValues({
                    ...values,
                    [e.target.name]: e.target.value
                })
            // }
    }
//select change
const handleSelectChange = (id,Name) => {
    // $('#input_Order_Item_Qty').focus()
    setItem({...item,Unit_Name:Name,Unit_ID:id})
    
}


const getAllUnits = () => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
        }
      };
      
    Axios.get("/api/getallunits",options)
        .then((result) =>{
        SetUnits(result.data.Units)
         } )
        
    .catch((err) => {
        console.log("ERROR: ====", err);
      })
        
    
}

useEffect(()=>{
    getAllUnits()
},[])

//
// add order fucctionality start //
const  AddOrUpdateOrder = ()=>{
    let endPoint 
    values.Order_ID === null ? endPoint = 'addorder': endPoint = 'updateorder'

    const data = {
        Order_ID: values.Order_ID,
        Area_ID:values.Area_ID,
        Unit_ID:values.Unit_ID,
        Unit_Name:values.Unit_Name,
        Area_Name:values.Area_Name,
       Customer_ID:values.Customer_ID,
       Customer_Name:values.Customer_Name,
       Order_Date: misc.formatDateyearmonthday(Order_Date),
       Order_Reference:values.Order_Reference,
       Order_Amount:values.Order_Amount,
        Items:props.orderItemsList.Order_Items_List
    }
    const options = {
        headers: {
            'Content-Type': 'application/json',
        }
      };

    Axios.post(`/api/${endPoint}`,data,options)
        .then((res) =>{
            setNotify({
                isOpen: true,
                message: res.data.message,
                type: res.data.iserror ? 'error':'success'
            })
            if (res.data.iserror === false) {
                setItem({Item_ID:"",Unit_ID:"",Unit_Name:"",Item_Name: "", Item_Code: "", Order_Item_Qty: "", Order_Item_Price: "",Order_Item_Amount:"" })
                setValues((prevState) => ({ ...prevState,Order_ID:null,Order_Amount:"00.00" }));
                props.emptyOrderItemsList()
                $("#input_Order_Search_Area").focus()
                history.push('/vieworders')
            }
        })
    .catch((error) => {
        setNotify({
            isOpen: true,
            message: error.response.data.message,
            type: error.response.data.iseror ? 'error':'success'
        })

      })
}
// add order fucctionality end //

// item fucctionality start //
    const [item,setItem]=useState({Item_ID:"",Unit_ID:"",Unit_Name:"",Item_Name: "", Item_Code: "", Order_Item_Qty: "", Order_Item_Price: "",Order_Item_Amount:"" })
    const [searchItemName,setSearchItemName] =useState(null)
    const [Item_List,set_Item_List] =useState([])

    const getIltems = () => {
        const data = {searchItemName:searchItemName}
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
          };
          
        Axios.post("/api/getallitems",data,options)
			.then((res) => set_Item_List(res.data.result) )
        .catch((err) => {
            console.log("ERROR: ====", err);
          })
			
        
    }

    const changeSearchItemState = (e) => { 
        const searchItemNameValue = e.target.value
      if (searchItemNameValue !== '') {
        setSearchItemName(searchItemNameValue)
        setItem({...item,Item_Name:searchItemNameValue})
      } else {
           setSearchItemName(null)
           setItem({...item,Item_Name:''})
      }
    }
// this will use after addAndModifyItem function set item.
    const handleItemInputChange = e => {
        if(e.target.name === "Order_Item_Qty" || e.target.name === "Order_Item_Price"){
            setItem({
                ...item,
                [e.target.name]: Math.round( e.target.value)
            })
        }else{
        setItem({
            ...item,
            [e.target.name]: e.target.value
        })
    }
}


    let addAndModifyItem=(ModifyItem)=>{
        setItem({Item_ID:ModifyItem.Item_ID,Unit_ID:ModifyItem.Unit_ID,Unit_Name: ModifyItem.Unit_Name,Item_Name: ModifyItem.Item_Name, Item_Code: ModifyItem.Item_Code, Order_Item_Qty: ModifyItem.Order_Item_Qty?parseInt(ModifyItem.Order_Item_Qty):1, Order_Item_Price:parseInt(ModifyItem.Order_Item_Price?ModifyItem.Order_Item_Price:ModifyItem.Item_Price)})
        setSearchItemName(null)
        $('#Order_Item_Unit').focus()
        
    }
    addAndModifyItem=addAndModifyItem.bind(this)

    useEffect(()=>{
        getIltems()
    },[searchItemName])
    
    useEffect(()=>{
        if(location.state){
        setValues({Order_ID:location.state.order.Order_ID,Customer_ID:location.state.order.Customer_ID,Area_ID:location.state.order.Area_ID,Area_Name:location.state.order.Area_Name,Customer_Name:location.state.order.Customer_Name,Order_Date:location.state.order.Order_Date,Order_Reference:location.state.order.Order_Reference,Order_Amount:location.state.order.Order_Amount})
        console.log(location.state)
        }
    },[location.state])

    const manageItemTotal = ()=>{
        const total = item.Order_Item_Qty * item.Order_Item_Price
        setItem({...item,Order_Item_Amount:total})

    }
    useEffect(()=>{
        manageItemTotal()
    },[item.Order_Item_Price,item.Order_Item_Qty])
    const AddItem =(item)=>{
        console.log(item)
        if (item.Order_Item_Price > 0 && item.Order_Item_Qty > 0 && item.Order_Item_Amount > 0 && item.Item_Name !=="" && item.Item_Code !=="" && item.Item_ID && item.Unit_ID !==undefined||null||"" && item.Unit_Name !==undefined||null||"" ) {
            props.addItem(item)
            setItem({Item_ID:"",Unit_ID:"",Unit_Name:"",Item_Name: "", Item_Code: "", Order_Item_Qty: "", Order_Item_Price: "",Order_Item_Amount:"",})
        } else {
            setNotify({
                isOpen: true,
                message: "price and quantity should be greater then 0 and check if any field is empty ",
                type:  'error'
            })
        }

    }
    
    // item fucctionality end //

    // customer Search Funcality Start//
    const [searchCustomerName,setSearchCustomerName] =useState(null)
    const [Cuctomer_List,set_Cuctomer_List] =useState([])
    const getCustomers = () => {
        const data = {
            searchCustomerName: searchCustomerName,
            Area_ID:values.Area_ID
        }
        const options = {
            headers: {
                'Content-Type': 'application/json',
            }
          };
        Axios.post("/api/getallcustomers",data,options)
			.then((res) =>{ 
                set_Cuctomer_List(res.data.result) 
                $('.form_search_customer_search-list_item').focus()} )
        .catch((err) => {
            console.log("ERROR: ====", err);
          })
			
        
    }

    let changeCustomerSearchState = (e) => {
        const searchCustomerNameValue = e.target.value
      if (searchCustomerNameValue !== '') {
        setSearchCustomerName(searchCustomerNameValue)
        setValues({ ...values,Customer_Name:searchCustomerNameValue})
      } else {
        setSearchCustomerName(null)
        setValues({ ...values,Customer_Name:''})
      }
        
    }
    changeCustomerSearchState = changeCustomerSearchState.bind(this)
    let setcustomer = (customer)=>{
        setValues({ ...values,Customer_Name:customer.Customer_Name,Customer_ID:parseInt(customer.Customer_ID),Area_ID:parseInt(customer.Area_ID),Area_Name:customer.Area_Name})
        setSearchCustomerName(null)
        
        // $('#input_Order_Date').focus()
    }
    setcustomer =setcustomer.bind(this)
    useEffect(()=>{
        getCustomers()
     
    },[searchCustomerName])
// customer Search Funcality end //
    
// Area Search Funcality Start//
const [searchAreaName,setSearchAreaName] =useState(null)
const [Area_List,set_Area_List] =useState([])
const getAreas = () => {
    const data = {
        searchAreaName: searchAreaName,
        Area_ID:values.Area_ID
    }
    const options = {
        headers: {
            'Content-Type': 'application/json',
        }
      };
    Axios.post("/api/getallareas",data,options)
        .then((res) =>{ 
            set_Area_List(res.data.result) 
            $('.form_search_area_search-list_item').focus()} )
    .catch((err) => {
        console.log("ERROR: ====", err);
      })
        
    
}

let changeAreaSearchState = (e) => {
    const searchAreaValue = e.target.value
  if (searchAreaValue !== '') {
    setSearchAreaName(searchAreaValue)
    setValues({ ...values,Area_Name:searchAreaValue})
  } else {
    setSearchAreaName(null)
    setValues({ ...values,Area_Name:'',Area_ID:""})
  }
    
}
changeAreaSearchState = changeAreaSearchState.bind(this)
let setArea = (area)=>{
    setValues({ ...values,Area_Name:area.Area_Name,Area_ID:parseInt(area.Area_ID),Customer_Name:"",Customer_ID:""})
    setSearchAreaName(null)
    // $('#input_Order_Date').focus()
}
setArea =setArea.bind(this)
useEffect(()=>{
    getAreas()
},[searchAreaName])
// Area Search Funcality end //
setArea =setArea.bind(this)
useEffect(()=>{
    console.log(item)
},[item])

const onAddOrUpdateOrder = () => {
    setConfirmDialog({
        ...confirmDialog,
        isOpen: false
    })
    AddOrUpdateOrder();
}

useEffect(()=>{
    if (item.Unit_ID !== undefined && item.Unit_ID !== "" ) {
        $('#input_Order_Item_Qty').focus()
    }
    // else if (item.Item_ID !== undefined && item.Item_ID !== "" ){
    //     $('#Order_Item_Unit').focus()
    // }
},[item.Unit_ID])

    return (
        <>
        {/* <PageHeader 
                title="Users"
                subTitle="Admin can Add Delete And Edit Users"
                icon={<NoteAdd color="primary" fontSize="large" />}
            /> */}
            <Paper className={classes.pageContent}>
           
                <Grid  container>
                
                <Grid className={classes.AddOrderHeader} container lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={2} md={2} sm={6} xs={8}>
                <div className='form_search_area' name="div">
                                            <Controls.SearchList Input_ID="input_Order_Search_Area" form_Name="area" ItemName="Area_Name" items={Area_List} setSelected={setArea} >
                                                <Controls.Input
                                    label="Search Areas"
                                    width="100%"
                                    tabIndex={tabIndex}
                                    id="input_Order_Search_Area"
                                    nextid="input_Order_Search_Customer"
                                    value={values.Area_Name}
                                    onChange={changeAreaSearchState}
                                    placeholder="Search Areas"
                                    InputProps={{
                                            startAdornment: (<InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>)
                                        }}
                                    />
                                            </Controls.SearchList>
                                            
                                
                                </div>
                        </Grid>
                        <Grid  className={classes.mobileSaveBtn} item lg={0} md={0} sm={0} xs={4}>
                        <Controls.Button 
                        accesskey="s"
                        text={values.Order_ID === null  ? "Save": 'update'}
                        onClick={() => {
                            setConfirmDialog({
                            isOpen: true,
                            title: `Are you sure to ${values.Order_ID === null  ? "save": 'update'}`,
                            subTitle: "You can't undo this operation",
                            onConfirm: () => { onAddOrUpdateOrder() }
                            })
                            }}
                        id="button_Order_Save_Or_Udate_Button"
                        nextid="input_Order_Search_Area"
                        className={classes.newButton}
                        height="4rem"
                         >
                        </Controls.Button>
                        </Grid>
                    <Grid  item lg={4} md={4} sm={6} xs={12}>
                    <div className='form_search_customer' name="div">
                                            <Controls.SearchList Input_ID="input_Order_Search_Customer" ItemName="Customer_Name" form_Name="customer" items={Cuctomer_List} setSelected={setcustomer} >
                                                <Controls.Input
                                    label="Search customers"
                                    width="100%"
                                    tabIndex={tabIndex}
                                    id="input_Order_Search_Customer"
                                    nextid="input_Order_Date"
                                    value={values.Customer_Name}
                                     onChange={changeCustomerSearchState}
                                    placeholder="Search customers"
                                    InputProps={{
                                            startAdornment: (<InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>)
                                        }}
                                    />
                                            </Controls.SearchList>
                                            
                                
                                </div>
                        {/* <IconButton onClick={addNewRow} type="button" className="btn btn-primary text-center"><Add/></IconButton> */}
                    </Grid>
                    
                    <Grid item lg={2} md={2} sm={3} xs={6}>
                    <Controls.DateInput 
                    label="Order Date"
                            width="100%"
                            name="Order_Date"
                            type="date"
                            id="input_Order_Date"
                            nextid='input_Order_Reference'
                            tabIndex={tabIndex}
                         value={ Order_Date}
                          onChange={(Order_Date)=>set_Order_Date(Order_Date)}>
                        </Controls.DateInput>
                        </Grid>
                        <Grid item lg={2} md={2} sm={4} xs={6}>
                        <Controls.Input 
                        label="Reference No"
                         width="100%"
                         type="text"
                         name="Order_Reference"
                         id="input_Order_Reference"
                         nextid="input_Order_Item_Name"
                         placeholder="Reference No"
                         value={values.Order_Reference}
                         onChange={handleInputChange}>
                        </Controls.Input>
                    </Grid>
                    {/* <Grid item  className={classes.orderID}  lg={2} md={2} sm={4} xs={6} >
                        <Typography  variant="h5">Oder ID:&nbsp;{values.Order_ID}</Typography>
                    </Grid> */}
                    

                </Grid>
                    
             
                                           
                </Grid>
        
                <div className="table-container" >
                <TblContainer>
                    <TblHead/>
                <TableBody>
               { props.orderItemsList.Order_Items_List !==null?(props.orderItemsList.Order_Items_List.map((Order_Items_List_Item,index) =>
                                (<TableRow  key={index} >
                                    <TableCell name="name" data-heading='Item Name' >{Order_Items_List_Item.Item_Name}</TableCell>
                                    <TableCell name="unit" className="unit" data-heading='Unit' >{Order_Items_List_Item.Unit_Name}</TableCell>
                                    <TableCell name="code" className="code" data-heading='Item Code' >{Order_Items_List_Item.Item_Code}</TableCell>
                                    <TableCell name="qty" data-heading='Item QTY ' >{misc.LZero(Order_Items_List_Item.Order_Item_Qty,2)}</TableCell> 
                                    <TableCell name="amount" data-heading='Item Price' >{Order_Items_List_Item.Order_Item_Price}</TableCell>
                                    <TableCell name="amount" data-heading='Item Amount' >{Order_Items_List_Item.Order_Item_Amount}</TableCell>
                                    <TableCell name="actions" className={classes.ActionBtnDiv} data-heading='Action' >
                                        <Controls.ActionButton
                                            onClick={()=>props.deleteItem(index)}
                                            colorPrimary="colorSecondary">
                                            <DeleteForever fontSize="small" />
                                        </Controls.ActionButton>
                                        <Controls.ActionButton
                                        onClick={()=>{addAndModifyItem(Order_Items_List_Item)}}
                                        colorPrimary="colorPrimary">
                                            <Edit fontSize="small" />
                                        </Controls.ActionButton>
                                    </TableCell>
                                   
                                </TableRow>)
                            )):null}
                </TableBody>
                </TblContainer>
                </div>
                <Grid className={classes.AddOrderHeader} container lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <div className='form_search_item' >
                                            <Controls.SearchList Input_ID="input_Order_Item_Name" ItemName="Item_Name" ItemCode="Item_Code" SystemID="System_ID" form_Name="item" items={Item_List} setSelected={addAndModifyItem} >
                                                <Controls.Input
                                    label="Item Name"
                                    width="100%"
                                    name="Item_Name"
                                    id="input_Order_Item_Name"
                                    nextid="Order_Item_Unit"
                                    tabIndex={tabIndex}
                                    value={item.Item_Name}
                                    onChange={changeSearchItemState}
                                        placeholder="Search Items"
                                        aria-activedescenda
                                        InputProps={{
                                            startAdornment: (<InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>)
                                        }}
                                    ></Controls.Input>
                                            </Controls.SearchList>
                                           
                                </div>
                    </Grid>
                        <Grid item lg={1} md={2} sm={3} xs={3}>
                        <Controls.SelectCustom
                        name="Unit_Name"
                        label="Units"
                        id="Order_Item_Unit"
                        nextid="input_Order_Item_Qty"
                        value={item.Unit_Name}
                        onChange={handleSelectChange}
                        options={Units}
                        
                    />
                        </Grid>
                        <Grid item lg={1} md={1} sm={3} xs={2}>
                        <Controls.Input label="QTY"
                         width="100%"
                         type="number"
                         id="input_Order_Item_Qty"
                        nextid="input_Order_Item_Price"
                         name="Order_Item_Qty"
                         varient="qty"
                         value={item.Order_Item_Qty===0?"":item.Order_Item_Qty}
                         onChange={handleItemInputChange}>
                        </Controls.Input>
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={3}>
                        <Controls.Input label="Price"
                         width="100%"
                         name="Order_Item_Price"
                         id="input_Order_Item_Price"
                         nextid="button_Order_Add_Button"
                         type="number"
                         varient="amount"
                         value={item.Order_Item_Price===0?"":item.Order_Item_Price}
                         onChange={handleItemInputChange}>
                        </Controls.Input>
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={4}>
                        <Controls.Input label="Total"
                         width="100%"
                         disabled={true}
                         type="number"
                         name="Order_Item_Amount"
                        //  id="input_Order_Item_Amount"
                        //  nextid="button_Order_Add_Button"
                         varient="amount"
                         value={item.Order_Item_Amount}
                         onChange={handleItemInputChange}>
                        </Controls.Input>
                        </Grid>
                        <Grid className={classes.mobileAddBtn} item lg={1} md={1} sm={2} xs={3}>
                        <Controls.Button 
                        text="ADD"
                        height="4rem"
                        id="button_Order_Add_Button"
                        nextid="input_Order_Item_Name"
                        onClick={()=>{AddItem(item)}}
                        className={classes.newButton}
                         >
                        </Controls.Button>
                        </Grid>
                        <Grid  container className={classes.mobileTotalDiv}  item lg={0} md={0} sm={0} xs={9}>
                        <Typography  variant="h4" color="textSecondary" >Total: {misc.currencyOnlyDecimal(values.Order_Amount)}</Typography>
                    </Grid>
                </Grid>
                <Grid className={classes.TotalDiv} container >
                <Grid  item lg={2} md={2} sm={3} xs={4}>
                <Controls.Button 
                        accesskey="s"
                        text={values.Order_ID === null  ? "Save": 'Update'}
                        onClick={() => {
                            setConfirmDialog({
                            isOpen: true,
                            title: `Are you sure to ${values.Order_ID === null  ? "save": 'spdate'} ?`,
                            subTitle: "You can't undo this operation",
                            onConfirm: () => { onAddOrUpdateOrder() }
                            })
                            }}
                        id="button_Order_Save_Or_Udate_Button"
                        nextid="input_Order_Search_Area"
                        className={classes.newButton}
                        height="4rem"
                         >
                        </Controls.Button>
                    </Grid>
                    <Grid  container style={{justifyContent:"flex-end"}} lg={9} md={9} sm={8} xs={8}>
                        <Typography   variant="h4" color="textSecondary" >Total: {misc.currencyOnlyDecimal(values.Order_Amount)}</Typography>
                    </Grid>
                </Grid>
                {/* <Controls.Button text="Submit"></Controls.Button> */}
            </Paper>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
         <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    )
    
}

const mapStateToProps = (state) => ({

    orderItemsList: state.orderItemsList,

})

  const mapDispatchToProps = dispatch=>({
    deleteItem: (id) => {
        dispatch(deleteItem(id))
    },
    addItem: (items) => {
        dispatch(addItem(items))
        
    },
    emptyOrderItemsList:()=>{dispatch(emptyOrderItemsList())}
  })
  export default connect(mapStateToProps,mapDispatchToProps)(AddOrder)

