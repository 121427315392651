import React, { useState,useRef, useEffect } from 'react'
import UnitForm from "./UnitsForm";
import PageHeader from "../../components/PageHeader";
import axios from 'axios'
import ListAlt from '@material-ui/icons/ListAlt';
import { Paper, makeStyles, TableBody, TableRow, TableCell, Toolbar, InputAdornment, Box,Grid, IconButton, } from '@material-ui/core';
import useTable from "../../components/useTable";
import Controls from "../../components/controls/Controls";
import {connect} from 'react-redux'
import { Search,LocationCity } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import Print from '@material-ui/icons/Print';
import FileCopy from '@material-ui/icons/FileCopy';
import Popup from "../../components/Popup";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close'; 
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import {GetUnits,EmptyUnits,DeleteUnit} from '../../redux/actions/UnitsAction'
import jsPDF from 'jspdf';
import misc from '../../fnc/misc'
import 'jspdf-autotable';
const useStyles = makeStyles(theme => ({

    pageContent: {
        padding: "2rem 2rem 0 2rem",
        height: `calc(${window.innerHeight}px - 150px)`,
        [theme.breakpoints.up('md')]:{
            height: `calc(${window.innerHeight}px - 200px)`,
        },
        overflow: "hiddin",
        "& .MuiGrid-item > *":{
       
            maxWidth: `calc(100% - 1rem) !important`,
            width: `calc(100% - 1rem) !important`,
            marginRight: `1rem !important`
       },
       "& .table-container":{
        height: `calc(${window.innerHeight}px - 250px)`,
        overflow:"auto",
        "& [name=actions]":{
            "& svg":{
                visibility:"hidden"
            }
        },
        "& #scroll_Btn":{
            margin:"auto",display:"none",
            [theme.breakpoints.between("xs","sm")]:{
                display:"block"
            }
        }
    }
    },
    Toolbar:{
    },
    
    ToolbarItems:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        [theme.breakpoints.between("xs","sm")]:{
            
            paddingLeft:"1rem"
        },
    },
    
       
    buttonContainerGrid:{
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-end",
        [theme.breakpoints.between("xs","sm")]:{
            marginRight:"2rem",
        },
        "& button":{
           
            width:"32.333%",
            [theme.breakpoints.between("md","xl")]:{
                marginRight:".3rem",
            },
        },

 
  },
    newButtonMobile:{
        padding:0,
        margin:0,
        [theme.breakpoints.down('md')]: {
            display:"flex"
          },
          [theme.breakpoints.up('md')]: {
            display:"none"
          },
    },
    newButton:{
        height: "4rem",
        margin:0,
        [theme.breakpoints.down('md')]: {
            display:"none"
          },
          [theme.breakpoints.up('md')]: {
            display:"flex"
          },
    }
}))

const defaultProps = {
    bgcolor: 'background.paper',
    borderColor: 'inherit',
    m: 1,
    padding:0,
    border: 1,
    style: { width: '4rem', height: '4rem' },
    display:"flex",
    margin:"0rem",
    alignItems:"center",
    justifyContent:"center",
    
  };
const headCells = [
    { id: 'Unit_Name', label: 'Unit Name' },
    { id: 'Unit_ID', label: 'Unit ID', name:"id" },
    { id: 'System_Unit_ID', label: 'System Unit ID',name:"id" },
    { id: 'Pcs_In_Unit', label: 'Pcs In Unit',name:"amount" },
    { id: 'actions', label: 'Actions', name:'actions' }
]

const Units = (props)=> {

    const classes = useStyles();
    const [recordForEdit, setRecordForEdit] = useState(null)
    const [filterFn, setFilterFn] = useState({ fn: units => { return units; } })
    const [openPopup, setOpenPopup] = useState(false)
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const componentRef = useRef();
    const [loading, setLoading] = useState(false)
    const [searchUnitName,setSearchUnitName] =useState(null)
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
        rowsPerPage,
        page,
        setPage,
        handleScroll
    } = useTable(props.Units_List, headCells, filterFn,componentRef,'medium',props.totalPages,props.currentPage,props.totalUnits,props.getAllUnits,props.EmptyUnits,true,setLoading);
    const handleSearch = e => {
        let target = e.target;
        setFilterFn({
            fn: units => {
                if (target.value == "")
                    return units;
                else
                    return units.filter(x => `${x.Unit_Name}${x.System_Unit_ID}`.toLowerCase().includes(target.value)||`${x.Unit_Name}${x.System_Unit_ID}`.toUpperCase().includes(target.value)||  misc.capitalize(`${x.Unit_Name}${x.System_Unit_ID}`).includes(target.value))
            }
        })
    }

   
// generte pdf code
const generatePdf = ()=> {
var doc = new jsPDF();
doc.autoTable({
    html: '#table',
    theme:'grid',
    textColor:22
});
doc.save('table.pdf');
};


// generate pdf code
    const insertUnit = (unit)=>{
        console.log('insert run')
        const headers={
          "x-access-token":sessionStorage.getItem("accountingauthkey")
      }
     axios.post('/api/addunit',unit,{headers:headers}).then(res=>{
         setNotify({
        isOpen: true,
        message: res.data.message,
        type: res.data.iserror ? 'error':'success'
    })
    if(res.data.iserror === false){
    props.EmptyUnits()
    setPage(0)
    props.getAllUnits({page:0,limit:rowsPerPage},setLoading)
    }
      }
      )
    }
    const updateUnit = (unit)=>{
        console.log('update run')
        console.log(unit)
        const headers={
          "x-access-token":sessionStorage.getItem("accountingauthkey")
      }
     axios.post('/api/updateunit',unit,{headers:headers}).then(res=>{
         setNotify({
        isOpen: true,
        message: res.data.message,
        type: res.data.iserror ? 'error':'success'
    })
    if(res.data.iserror === false){
        props.EmptyUnits()
        setPage(0)
        props.getAllUnits({page:0,limit:rowsPerPage},setLoading)
        }
     })
    }
    const addOrEdit = (Unit, resetForm) => {
  
        if (!Unit.Unit_ID){
          
           insertUnit(Unit)
        }
        else if( Unit.Unit_ID  ){
            updateUnit(Unit)
        }
        resetForm()
        setRecordForEdit(null)
        setOpenPopup(false)
    }


    const openInPopup = unit => {
        setRecordForEdit(unit)
        setOpenPopup(true)
    }
   const deleteUnit=(unitName,unitID)=>{
    const headers={
      "x-access-token":sessionStorage.getItem("accountingauthkey")
  }
  console.log('delete run',unitID)
 axios.post('/api/deleteunit',{Unit_ID:unitID},{headers:headers}).then(res=>{
     setNotify({
    isOpen: true,
    message: res.data.message,
    type: res.data.iserror ? 'error':'success'
})
if(res.data.iserror === false){
    props.EmptyUnits()
    setPage(0)
    props.getAllUnits({page:0,limit:rowsPerPage},setLoading)
    }
 })
    }
    const changeSearchState = (e) => {
        const searchUnitNameValue = e.target.value
      if (searchUnitNameValue !== '') {
        setSearchUnitName(searchUnitNameValue)
      } else {
           setSearchUnitName(null)
      }
        
    }

    const onDeleteUnit = (UnitName,Unit_ID) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
       deleteUnit(null,Unit_ID)
    }


    return (
        <>
            <PageHeader 
                title="Units"
                icon={<LocationCity color="primary" fontSize="large" />}
            >
            <Grid container  className={classes.ToolbarItems} lg={8} md={9} sm={12} xs={12} >
                <Grid container lg={5} md={5} sm={6} xs={6}   >
                    <Controls.Input
                        label="Search"
                        width="100%"
                        InputProps={{
                            startAdornment: (<InputAdornment position="start">
                                <Search />
                            </InputAdornment>)
                        }}
                        onChange={handleSearch}
                    />
                    </Grid>
                    <Grid className={classes.buttonContainerGrid}   container lg={7} md={7} sm={4} xs={5} >
                    
                    <IconButton
                        variant="outlined"
                        className={classes.newButtonMobile}
                        onClick={() => { setOpenPopup(true); setRecordForEdit(null); }}
                    ><Box borderRadius="50%" {...defaultProps}><AddIcon /></Box></IconButton>
                    <Controls.Button
                        text="Add New"
                        width="100%"
                        height="4rem"
                        startIcon={<AddIcon />}
                        className={classes.newButton}
                        onClick={() => { setOpenPopup(true); setRecordForEdit(null); }}
                    />
                
                    <IconButton
                        className={classes.newButtonMobile}
                        onClick={generatePdf}
                    ><Box borderRadius="50%" {...defaultProps}><Print /></Box></IconButton>
                    <Controls.Button
                        text="Print"
                        width="100%"
                        height="4rem"
                        startIcon={<Print/>}
                        className={classes.newButton}
                        onClick={generatePdf}
                    />

                    <IconButton
                        
                        className={classes.newButtonMobile}
                        onClick={()=>misc.exportTableToExcel('table')}
                    ><Box borderRadius="50%" {...defaultProps}><FileCopy /></Box></IconButton>
                    <Controls.Button
                        text="Excel"
                        width="100%"
                        height="4rem"
                        startIcon={<FileCopy/>}
                        className={classes.newButton}
                        onClick={()=>misc.exportTableToExcel('table')}
                    />
                    
                  
                    </Grid>
                </Grid>
            </PageHeader>
            <Paper elevation={7} className={classes.pageContent}>
            <div className="table-container" onScroll={handleScroll}>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {props.Units_List !== null?(
                            recordsAfterPagingAndSorting().map(Units_List_Unit =>
                                (<TableRow key={Units_List_Unit.Unit_ID} >
                                    <TableCell  data-heading='Unit Name' >{Units_List_Unit.Unit_Name}</TableCell>
                                    <TableCell name="id" data-heading='Unit ID' >{Units_List_Unit.Unit_ID}</TableCell>
                                    <TableCell name="id" data-heading='System Unit ID' >{Units_List_Unit.System_Unit_ID}</TableCell>
                                    <TableCell name="amount"   data-heading='Pcs In Unit' >{Units_List_Unit.Pcs_In_Unit}</TableCell>
                                    <TableCell  data-heading='Action' >
                                        <>
                                        <Controls.ActionButton
                                            colorPrimary="colorPrimary"
                                            onClick={() => { openInPopup(Units_List_Unit) }}>
                                            <EditOutlinedIcon fontSize="small" />
                                        </Controls.ActionButton>
                                        <Controls.ActionButton
                                        colorPrimary="colorSecondary"
                                            onClick={() => {
                                                setConfirmDialog({
                                                isOpen: true,
                                                title: 'Are you sure to delete ?',
                                                subTitle: "You can't undo this operation",
                                                onConfirm: () => { onDeleteUnit(null,Units_List_Unit.Unit_ID) }
                                                })
                                                }}>
                                            <CloseIcon fontSize="small" />
                                        </Controls.ActionButton></>
                                    </TableCell>
                                </TableRow>)
                            )):(<Controls.Loader/>)
                        }
                        {page < props.totalPages ?<Controls.Button id="scroll_Btn"  width="100%" height="4rem" text="Load" onClick={()=>page < props.totalPages ? setPage(page+1):null}></Controls.Button>:null}
                    </TableBody>
                    
                </TblContainer>
                {loading?<Controls.Loader/>:null}
                </div>
<table id="table" style={{width:" 100%",borderCollapse: "collapse",border: "1px solid black",display:"none"}} >
  <tr>
    {headCells.map((Units_List_Unit,index)=> Units_List_Unit.label !== "Actions"?<th key={index} style={{border: "1px solid black"}}>{Units_List_Unit.label}</th>:null)}
  </tr>
  {props.Units_List?recordsAfterPagingAndSorting().map((Units_List_Unit,index)=>(
  <tr key={index}>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='ITEM Name' >{Units_List_Unit.Unit_Name}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='SYSTEM ID' >{Units_List_Unit.Unit_ID}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='ITEM CODE' >{Units_List_Unit.System_Unit_ID}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='PRICE' >{Units_List_Unit.Pcs_In_Unit}</td>
</tr>)):null}
</table>
            
            </Paper>
            <Popup
                title="Units Form"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <UnitForm
                    recordForEdit={recordForEdit}
                    addOrEdit={addOrEdit} />
            </Popup>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        
        </>
    )
}
const mapStateToProps=state=>({ 
    Units_List: state.UnitsList.Units_List,
    totalPages:state.UnitsList.totalPages,
    currentPage:state.UnitsList.currentPage,
    totalUnits:state.UnitsList.totalUnits
  })
  const mapDispatchToProps = dispatch=>({
    getAllUnits : (data,setLoading)=>{dispatch(GetUnits(data,setLoading))},
    EmptyUnits:()=>{dispatch(EmptyUnits())},
    DeleteUnit:(unitID,totalPages,totalUnits)=>{dispatch(DeleteUnit(unitID,totalPages,totalUnits))}
  })
  export default connect(mapStateToProps,mapDispatchToProps)(Units)

