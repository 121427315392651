import {GETUNITS,UPDATEUNIT,DELETEUNIT,EMPTYUNITS} from './types'
import axios from 'axios'

export const GetUnits =(data,setLoading)=>dispatch=>{
    const headers={
        "x-access-token": sessionStorage.getItem("accountingauthkey")
    }
    axios.post('/api/getunits',data,{headers:headers}).then(res=>{ 
    dispatch({type:GETUNITS,payload:res.data})
    setLoading(false)
  })
}

export const EmptyUnits =()=>dispatch=>{
  dispatch({type:EMPTYUNITS})
}
export const UpdateUnit=(unit)=>dispatch=>{
  const headers={
      "x-access-token": sessionStorage.getItem("accountingauthkey")
  }
  axios('/api/updateunit',unit,headers).then(res=>dispatch({type:UPDATEUNIT,payload:res.message}))
}

export const DeleteUnit =(unitID,totalPages,totalUnits)=>(
  {type:DELETEUNIT,
  payload:{unitID,totalPages,totalUnits}}
)